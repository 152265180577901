import React, { useEffect, useState } from "react";
import Section from "../layouts/Section";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Alert } from "../../alertServices";
import { localPath, servicePath } from "../../constants/defaultsValues";

const apiResident = `${servicePath}/residents`;
function UserSetting() {
    const user = useSelector(state => state.user);
    const token = useSelector(state => state.jwt);

    const [resident, setResident] = useState({});
    const [editPicture, setEditPicture] = useState(false);
    const [editUsername, setEditUsername] = useState(false);
    const [image, setImage] = useState(null);
    const [validImage, setValidImage] = useState(false);
    const [imageURL, setImageURL] = useState([]);
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);

    async function fetchData() {
        axios.get(`${apiResident}/${user.resident.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                setResident(res.data);
                setName(res.data.name);
                setLastname(res.data.lastname);
                setEditPicture(false);
                setImageURL(null);
                setValidImage(false);
                setEditUsername(false);
                setImage(null);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const editPictureHandler = () => {
        setEditPicture(!editPicture);
    }

    const onEditUsername = () => {
        axios.put(`${apiResident}/${resident.id}`,
            {
                name,
                lastname
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then(() => {
                fetchData();
            })
    }

    const uploadImage = async () => {
        console.log(token);
        const imageFile = new FormData();
        imageFile.append('files', image);
        // console.log(imageFile);
            const oldImage = resident.profilePicture?.id || undefined;
            await axios
                .post(`${servicePath}/upload`, imageFile, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    const imageId = res.data[0].id;
                    axios
                        .put(
                            `${servicePath}/residents/${resident.id}`,
                            {
                                profilePicture: imageId,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .then(() => {
                            fetchData();
                            Alert.success({ title: 'Imagen de perfil actualizada' });
                            if (oldImage !== undefined) {
                                axios.delete(
                                    `${servicePath}/upload/files/${oldImage}`,
                                    {
                                        profilePicture: imageId,
                                    },
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    }
                                );
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
            // setIsSubmit(true);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1000);
        // setImage(null);
    };

    const onEmailChange = () => {
        // setEdit(e.target.value);
        axios
            .post(
                `${servicePath}/email/change-email-confirmation`,
                {
                    to: resident.email,
                    userId: resident.users[0].id,
                    userName: `${resident.name} ${resident.lastname}`,
                    redirectTo: `${localPath}/user/reset-email`,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(() => {
                fetchData();
                setEmailSuccess(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onPasswordChange = () => {
        axios
            .post(`${servicePath}/auth/forgot-password`, {
                email: resident.email,
            })
            .then(() => {
                setPasswordSuccess(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (image) {
            // console.log(image);
            const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
            const size = bytesToMegaBytes(image.size);
            if (
                size < 5 &&
                (image.type === 'image/jpeg' || image.type === 'image/png')
            ) {
                setValidImage(true);
                const newImageUrl = [];
                newImageUrl.push(URL.createObjectURL(image));
                setImageURL(newImageUrl);
            }
        }
    }, [image]);

    return (
        <Section allNotification={false} searchPopup={true} title={'User Setting'}>
            {resident ? (
                <div>
                    <div className="balance-area pd-top-40">
                        <div className="container">
                            <div className="text-center">
                                <h3 className="text-blue">Mi perfil</h3>
                            </div>
                            <div className="balance-area-bg pt-5 bg-user-setting">
                                <button type="button" onClick={() => editPictureHandler()} className="edit-title bg-transparent border-0">
                                    <div className="d-flex align-items-center">
                                        <span className="pr-1">{editPicture ? 'Cancelar' : 'Editar Foto'}</span>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.7714 1.0125C15.4214 -0.3375 13.2114 -0.3375 11.8614 1.0125L2.44141 10.4325C2.44141 10.4325 2.44141 10.4325 2.43141 10.4425C2.38141 10.4925 2.35141 10.5525 2.32141 10.6225L0.0314133 16.7625C-0.0385867 16.9425 0.0114133 17.1525 0.151413 17.2925C0.251413 17.3925 0.371413 17.4425 0.501413 17.4425C0.561413 17.4425 0.621413 17.4325 0.671413 17.4125L6.82141 15.1225C6.98141 15.0625 7.10141 14.9225 7.13141 14.7625C7.16141 14.6025 7.12141 14.4225 7.00141 14.3025L3.49141 10.8025L11.3314 2.9525L14.8314 6.4525L8.65141 12.6325C8.45141 12.8325 8.45141 13.1425 8.65141 13.3425C8.85141 13.5425 9.16141 13.5425 9.36141 13.3425L16.7814 5.9225C18.1314 4.5725 18.1314 2.3625 16.7814 1.0125H16.7714ZM5.75141 14.4425L1.35141 16.0825L2.99141 11.6825L5.75141 14.4425ZM16.0714 5.2125L15.5414 5.7425L12.0414 2.2425L12.5714 1.7125C13.5314 0.7525 15.1014 0.7525 16.0714 1.7125C17.0414 2.6725 17.0314 4.2425 16.0714 5.2125Z" fill="white" />
                                        </svg>
                                    </div>
                                </button>
                                <div className="ba-balance-inner text-center" style={{ backgroundImage: "url(assets/img/bg/2.png)" }}>
                                    <input
                                        id="image"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => setImage(e.currentTarget.files[0])}
                                        className="d-none"
                                    />
                                    {image && validImage && imageURL ? (
                                        <img
                                            alt="profile"
                                            className="rounded-circle"
                                            width="70"
                                            height="70"
                                            src={imageURL[0]}
                                        />
                                    ) : ''
                                    }
                                    {!image && resident.profilePicture?.url ? (
                                        <img
                                            alt="profile"
                                            className="rounded-circle"
                                            width="70"
                                            height="70"
                                            src={`${servicePath}${resident.profilePicture?.url}`}
                                        />
                                    )
                                        : ''}
                                    {!image && !resident.profilePicture ? (
                                        <img
                                            alt="profile"
                                            className="rounded-circle"
                                            width="70"
                                            height="70"
                                            src={process.env.PUBLIC_URL + `/assets/img/perfil.svg`}
                                        />
                                    ) : ''}
                                    {/* {!image ? (
                                        <div className="icon" style={{ scale: '1.3' }}>
                                            <img src={!resident.profilePicture || editPicture ? process.env.PUBLIC_URL + '/assets/img/other/1.png' : servicePath + resident.profilePicture?.url} alt="img" />
                                        </div>
                                    ) : ''} */}
                                    {editPicture ? (
                                        <div className="mt-3">
                                            {image ? (
                                                <button
                                                    type="button"
                                                    onClick={() => uploadImage()}
                                                    className="btn-sm border-0 btn-green mr-3"
                                                >
                                                    Guardar
                                                </button>
                                            ) : (
                                                <label
                                                    htmlFor="image"
                                                    className="btn-sm btn-blue mr-3"
                                                >
                                                    Subir foto
                                                </label>
                                            )}
                                            <button
                                                type="button" onClick={() => {
                                                    setEditPicture(!editPicture);
                                                    setImage(null);
                                                    setImageURL(null);
                                                    setValidImage(false);
                                                }}
                                                className="btn-sm border-0 btn-red">
                                                Cancelar
                                            </button>
                                        </div>
                                    ) : (
                                        <h5 className="title mb-0">{resident.name} {resident.lastname}</h5>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="transaction-details pd-top-36 mg-bottom-35">
                        <div className="container ba-main-menu">
                            <div className="text-center">
                                <h3 className="title text-blue">Configuración</h3>
                            </div>
                            <ul className="transaction-details-inner ul-main">
                                <li>
                                    <span className="float-left">Notificaciones Push</span>
                                    <span className="float-right">
                                        <span className="single-switch">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round" />
                                            </label>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span className="float-left">Sonido Notificaciones</span>
                                    <span className="float-right">
                                        <span className="single-switch">
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round" />
                                            </label>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <a
                                        className="setting-menu collapsed d-flex align-items-center justify-content-between bg-transparent border-0 w-100"
                                        data-toggle="collapse"
                                        href="#username"
                                    >
                                        Nombre de Usuario
                                    </a>
                                    <div
                                        id="username"
                                        className="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <div className="text-center mx-auto">
                                            <label htmlFor="name" className="mr-2">Nombre:</label>
                                            <input
                                                id="name"
                                                className={`input-shadow p-2 ${editUsername ? 'bg-transparent' : 'bg-gray'}`}
                                                disabled={!editUsername}
                                                onChange={(e) => setName(e.target.value)}
                                                defaultValue={resident.name}
                                            />
                                            <label htmlFor="lastname" className="mr-2 mt-2">Apellido:</label>
                                            <input
                                                id="lastname"
                                                className={`input-shadow p-2 mt-3 ${editUsername ? 'bg-transparent' : 'bg-gray'}`}
                                                disabled={!editUsername}
                                                onChange={(e) => setLastname(e.target.value)}
                                                defaultValue={resident.lastname}
                                            />
                                            {editUsername ? (
                                                <div className="mt-3 text-center">
                                                    <button type="button" onClick={() => onEditUsername()} className="btn btn-green border-0 text-white mr-3">
                                                        Guardar
                                                    </button>
                                                    <button type="button" onClick={() => setEditUsername(false)} className="btn btn-red border-0 btn-red text-white">
                                                        Cancelar
                                                    </button>
                                                </div>
                                            ) : (
                                                <button onClick={() => setEditUsername(!editUsername)} className="py-2 mt-3 btn btn-blue border-0 rounded d-flex align-items-center px-4 mx-auto">
                                                    <span className="mr-2 text-white">Editar</span>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill="white" d="M16.7714 1.0125C15.4214 -0.3375 13.2114 -0.3375 11.8614 1.0125L2.44141 10.4325C2.44141 10.4325 2.44141 10.4325 2.43141 10.4425C2.38141 10.4925 2.35141 10.5525 2.32141 10.6225L0.0314133 16.7625C-0.0385867 16.9425 0.0114133 17.1525 0.151413 17.2925C0.251413 17.3925 0.371413 17.4425 0.501413 17.4425C0.561413 17.4425 0.621413 17.4325 0.671413 17.4125L6.82141 15.1225C6.98141 15.0625 7.10141 14.9225 7.13141 14.7625C7.16141 14.6025 7.12141 14.4225 7.00141 14.3025L3.49141 10.8025L11.3314 2.9525L14.8314 6.4525L8.65141 12.6325C8.45141 12.8325 8.45141 13.1425 8.65141 13.3425C8.85141 13.5425 9.16141 13.5425 9.36141 13.3425L16.7814 5.9225C18.1314 4.5725 18.1314 2.3625 16.7814 1.0125H16.7714ZM5.75141 14.4425L1.35141 16.0825L2.99141 11.6825L5.75141 14.4425ZM16.0714 5.2125L15.5414 5.7425L12.0414 2.2425L12.5714 1.7125C13.5314 0.7525 15.1014 0.7525 16.0714 1.7125C17.0414 2.6725 17.0314 4.2425 16.0714 5.2125Z" />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a
                                        className="setting-menu collapsed d-flex align-items-center justify-content-between bg-transparent border-0 w-100"
                                        data-toggle="collapse"
                                        href="#email"
                                    >
                                        Correo electrónico
                                    </a>
                                    <div
                                        id="email"
                                        className="card-body collapse text-center"
                                        data-parent="#accordion"
                                    >
                                        <h2 className="text-center py-2">{resident.email}</h2>
                                        <button type="button" data-toggle="modal" data-target="#email-success" onClick={() => onEmailChange()} className="btn btn-blue text-white" >
                                            Cambiar correo electrónico
                                        </button>
                                    </div>
                                    {emailSuccess ? (
                                        <div id="email-success" className="modal">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-body text-center">
                                                        <div className="sa-icon sa-success animate" style={{ display: 'block' }}>
                                                            <span className="sa-line sa-tip animateSuccessTip" />
                                                            <span className="sa-line sa-long animateSuccessLong" />
                                                            <div className="sa-placeholder" />
                                                            <div className="sa-fix" />
                                                        </div>
                                                        <div className="section-title mt-5 mb-2">
                                                            <h2 className="text-gradient-02 text-center">Email enviado</h2>
                                                        </div>
                                                        <div className="mb-5">
                                                            Te enviamos un email con instrucciones para cambiar tu correo a
                                                            <a href={`mailto:${resident.email}`}>
                                                                <u>{resident.email}</u>
                                                            </a>
                                                        </div>
                                                        <button type="button" className="btn btn-shadow mb-3" data-dismiss="modal">Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </li>
                                <li>
                                    <a
                                        className="setting-menu collapsed d-flex align-items-center justify-content-between bg-transparent border-0 w-100"
                                        data-toggle="collapse"
                                        href="#password"
                                    >
                                        Cambiar Contraseña
                                    </a>
                                    <div
                                        id="password"
                                        className="card-body collapse text-center"
                                        data-parent="#accordion"
                                    >
                                        <button type="button" onClick={() => onPasswordChange()} data-toggle="modal" data-target="#password-success" className="btn btn-blue text-white" >
                                            Cambiar contraseña
                                        </button>
                                    </div>
                                    {passwordSuccess ? (
                                        <div id="password-success" className="modal">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-body text-center">
                                                        <div className="sa-icon sa-success animate" style={{ display: 'block' }}>
                                                            <span className="sa-line sa-tip animateSuccessTip" />
                                                            <span className="sa-line sa-long animateSuccessLong" />
                                                            <div className="sa-placeholder" />
                                                            <div className="sa-fix" />
                                                        </div>
                                                        <div className="section-title mt-5 mb-2">
                                                            <h2 className="text-gradient-02 text-center">Email enviado</h2>
                                                        </div>
                                                        <div className="mb-5">
                                                            Te enviamos un email con instrucciones para cambiar la contraseña a
                                                            <a href={`mailto:${resident.email}`}>
                                                                <u>{resident.email}</u>
                                                            </a>
                                                        </div>
                                                        <button type="button" className="btn btn-shadow mb-3" data-dismiss="modal">Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''}
                                </li>
                                <li>
                                    <Link to={'/user-setting'}>
                                        <span className="float-left">Cambiar Idioma</span>
                                        <span className="float-right">ES</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                <i className="loading" />
            )}

        </Section>
    );
}

export default UserSetting;

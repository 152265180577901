import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import io from 'socket.io-client';
import { servicePath } from "../../constants/defaultsValues";

const apiNotifications = `${servicePath}/notifications`;
const socket = io(servicePath);
const Header = ({ title, withBack, noBorder, cancelTo }) => {
  // const socket = io(servicePath);
  let history = useHistory();
  const resident = useSelector((state) => state.resident);
  const token = useSelector((state) => state.jwt);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const goBack = () => {
    history.goBack();
  };
  // const [search, setSearch] = useState(false);
  /* const searchPopup = (
    <>
      <div
        className={search ? "body-overlay active" : "body-overlay"}
        id="body-overlay"
        onClick={() => setSearch(false)}
      />
      <div
        className={search ? "search-popup active" : "search-popup"}
        id="search-popup"
      >
        <form className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="button" className="submit-btn">
            <i className="fa fa-search" />
          </button>
        </form>
      </div>
    </>
  ); */

  const fetchNotificationsCount = () => {
    axios.get(`${apiNotifications}/count?resident=${resident?.id}&viewed=false`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setNotificationsCount(res.data);
      });
  };

  useEffect(() => {
    if (resident) {
      fetchNotificationsCount();
    }
  }, []);

  useEffect(() => {
    socket.on('notification:incidence-resolved', (e) => {
      fetchNotificationsCount();
    });
    // return () => socket.off('notification:incidence-resolved');
  }, [socket]);

  return (
    <>
      {/* searchPopup ? searchPopup : "" */}
      {/* Header Starts */}
      <div
        id={`header-area${noBorder ? "2" : ""}`}
        className={`header-area ba-navbar-user p-0 d-flex ${noBorder ? "mt-4 bg-transparent" : "bg-transparent1 box"
          } `}
      >
        <div className="col-3 d-flex align-items-center justify-content-center">
          {!withBack ? (
            <div className="w-auto h-auto menu-bar">
              <i className="small-icon icon-menu" />
            </div>
          ) : (
            <div
              className="w-auto h-auto text-blue"
              style={{ marginTop: "8%" }}
            >
              <i className="small-icon icon-back" onClick={() => goBack()} />
            </div>
          )}
        </div>
        <div className="col-6 text-center d-flex align-items-center justify-content-center">
          {withBack ? (
            <span className="text-blue">{title}</span>
          ) : (
            <Link to="/">
              <img
                className="img-header"
                src={
                  process.env.PUBLIC_URL + "/assets/img/civity-logo-blue.svg"
                }
                alt="civity"
              />
            </Link>
          )}
        </div>
        <div className="col-3  d-flex align-items-center justify-content-center">
          {cancelTo ? (
            <Link to={cancelTo}>
              <i
                className="small-icon icon-cerrar p-0"
                style={{ marginTop: " 5px" }}
              ></i>
            </Link>
          ) : (
            <div className="row">
              <Link to={"/notifications"}>

                <i
                  className="icon-notificaciones p-0 m-0"
                  style={{ width: "60px" }}
                ></i>
                <ul className="header-right">
                  <li>
                    {/* <i className="fa fa-bell animated infinite swing" /> */}
                    <span className="badge">{notificationsCount <= 9 ? notificationsCount : '+9'}</span>
                  </li>
                </ul>
              </Link>

            </div>
          )}
        </div>
        {/*  <div className="w-33">
          {!withBack ? (
            <div className="w-50 menu-bar">
              <i className="small-icon icon-menu" />
            </div>
          ) : (
            <div
              className="w-50  text-center text-blue"
              style={{ marginTop: "8%" }}
            >
              <i className="small-icon icon-back" onClick={() => goBack()} />
            </div>
          )}
        </div> */}
        {/*
        <div className="w-33 text-center">
          {withBack ? (
            <p style={{ marginTop: "1.3rem" }}>
              <span className="text-blue">{title}</span>
            </p>
          ) : (
            <Link to="/">
              <img
                className="img-header"
                src={
                  process.env.PUBLIC_URL + "/assets/img/civity-logo-blue.svg"
                }
                alt="civity"
              />
            </Link>
          )}
        </div>

        {cancelTo ? (
          <div
            className="w-33 text-right notification"
            style={{ position: "absolute", right: "40px", top: "70%" }}
          >
            <Link to={cancelTo}>
              <i className="small-icon icon-cerrar"></i>
            </Link>
          </div>
        ) : (
          <div className="w-33 text-right notification">
            <Link to="#">
              <i className="large-icon icon-notificaciones"></i>
            </Link>
          </div>
        )} */}
      </div>
      {/* Header Ends */}
      {/*  <div
        className="header-area"
        style={{
          backgroundImage: "url(assets/img/bg/1.png)",
          backgroundColor: "purple",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-3 text-right">
              <Link className="menu-back-page home-clicked" to={"/"}>
                <i className="fa fa-angle-left" />
              </Link>
            </div>
            <div className="col-sm-4 col-6 text-center">
              {/* pending to review
              <div className="page-name">{"title"}</div>
            </div>
             <div className="col-sm-4 col-3 text-left">
              {searchPopup ? (
                <div
                  className="search header-search"
                  onClick={() => setSearch(true)}
                >
                  <i className="fa fa-search" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div> */}
      {/* Page Title
      <div className="page-title mg-top-50">
        <div className="container">
          <button className="text-left home-clicked" onClick={goBack}>
            {"<"}
          </button>
        </div>
      </div>  */}
    </>
  );
};

// const NotificationsModal = ({ notifications, history, token }) => {
//   const handleClick = async (n) => {
//     await axios.put(`${apiNotifications}/${n.id}`,
//       {
//         viewed: true,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//     switch (n.type) {
//       case 'incidence':
//       history.push(`incidences/monitoring/${n.incidence.id}`);
//       break;
//       case 'charge':
//       history.push(`payments`);
//       break;
//       case 'news':
//       history.push(`news`);
//       break;
//       default:
//       history.push('');
//       break;
//     }
//   }
//   const handleSeeAll = (n) => {
//     history.push('notifications');
//   }
//   const handleIcon = (type) => {
//     switch (type) {
//       case 'incidence':
//       return 'icon-incidencias';
//       case 'charge':
//       return 'icon-pagos';
//       case 'news':
//       return 'icon-noticias';
//       default:
//       return ''
//     }
//   }
//   return (
//     <div id="modal-notifications" className="modal fade" style={{ transform: 'translateY(50px)' }} tabIndex="-1" role="dialog" aria-hidden="true">
//       <div className="modal-dialog">
//         <div className="modal-content">
//           <div className="modal-body p-0">
//             {notifications.length ? (
//               <>
//                 {notifications.map((n) => {
//                   return (
//                     <>
//                       <button
//                         type="button"
//                         data-dismiss="modal"
//                         onClick={() => handleClick(n)}
//                         className={`ba-bill-pay-inner w-100 ${n.viewed ? 'bg-transparent' : 'bg-gray'}`}
//                       >
//                         <div className="ba-single-bill-pay">
//                           <div className="thumb">
//                             <i className={handleIcon(n.type)} />
//                           </div>
//                           <div className="text-left ml-3">
//                             <h5>{n.title}</h5>
//                             <p>{n.message}</p>
//                           </div>
//                         </div>
//                       </button>
//                     </>
//                   )
//                 }
//                 )}
//                 <div className="m-3 text-center">
//                   <button data-dismiss="modal" onClick={() => handleSeeAll()} className="btn bg-blue px-5">Ver todas</button>
//                 </div>
//               </>
//             ) : (
//               <div className="text-center my-5">
//                 Sin notificaciones
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

export default Header;

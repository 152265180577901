import { defaultDirection } from "../constants/defaultsValues";

export const formatter = new Intl.NumberFormat('en-EN', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const formatterDecimal = new Intl.NumberFormat('en-EN', {
  style: 'decimal',
  // currency: 'USD',
  minimumFractionDigits: 0,
});

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

// export const getCardIcon = (brand) => {

// };

import React, { useState } from "react";
import Section from "../layouts/Section";
import { Link, NavLink, Redirect, useHistory } from "react-router-dom";
import { loginUserStart } from "../../redux/auth/actions";
import { connect } from "react-redux";

// import { useSelector, useDispatch } from "react-redux";

// const goBack = () => {
//   useHistory.goBack();
// };
/* VALIDATIONS */
const validatePassword = (value) => {
  let error = null;
  if (!value) {
    error = "Campo obligatorio";
  } else if (value.length < 6) {
    error = "Password debe ser mayor a 6 caracteres";
  }
  return error;
};
const validateUsername = (value) => {
  let error = null;
  if (!value) {
    error = "Campo obligatorio";
  }
  return error;
};
/* END VALIDATIONS */
const Login = (jwt, message) => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);
  const [errors, setErrors] = useState({ password: null, username: null });
  // const dispatch = useDispatch();
  const jasonWebToken = jwt.jwt;
  localStorage.clear();

  const validateLogin = (name, pass) => {
    const validPass = validatePassword(pass);
    const validName = validateUsername(name);
    setErrors({
      password: validPass,
      username: validName,
    });
    return !validPass && !validName ? true : false;
  };
  const onLogin = async (e) => {
    e.preventDefault();
    if (validateLogin(username, password)) {
      await jwt.loginUserAction(username, password, history);
      setSubmit(true);
    }
  };

  if (jasonWebToken !== null) {
    return <Redirect to="/">Error, ya hay una cuenta iniciada</Redirect>;
  } else {
    return (
      <Section
        allNotification={false}
        searchPopup={true}
        title={"Login"}
        disabledHeader={true}
        disabledFooter={true}
      >
        <section
          className="section-login h-100"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/Fondo-login.png" +
              ")",
          }}
        >
          <div className="page-title bg-transparent">
            {/* <div className="container pt-3">
              <button
                className="text-left home-clicked go-back"
                onClick={goBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </button>
            </div> */}
          </div>
          <div className="text-center mg-bottom-40 title">
            <div className="col-md-4 col-5 mx-auto mb-5">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/civity-logo.svg"}
                alt="civity"
              />
            </div>
            <div className="col-12 text-center">
              <h4 className="text-center">Inicia Sesión</h4>
            </div>
          </div>

          <div className="Login-area">
            <div className="container">
              <form
                className="contact-form-inner bg-transparent"
                onSubmit={(e) => onLogin(e)}
              >
                {submit && jwt.message ? (
                  <div className="col-12 text-center mx-auto mb-3">
                    <span className="text-danger mx-auto">{jwt.message}</span>
                  </div>
                ) : null}
                <label className="single-input-wrap">
                  <input
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    placeholder="Email o usuario"
                  />
                  {errors.username ? (
                    <span className="text-danger">{errors.username}</span>
                  ) : null}
                </label>
                <label className="single-input-wrap">
                  <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Password"
                  />
                  {errors.password ? (
                    <span className="text-danger">{errors.password}</span>
                  ) : null}
                </label>
                <NavLink to="/reset-password" className="text-right">
                  <span>¿Olvidaste tu Contraseña?</span>
                </NavLink>
                <div className="w-100 text-center">
                  <button className="btn btn-login mx-auto" type="submit">
                    Iniciar sesión
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* <div className="social-login">
            <div className="text-center mb-3">
              <span>Entrar con</span>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto text-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/icon/login-FB.svg"}
                  alt="facebook"
                />
              </div>
              <div className="col-auto text-center p-0">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icon/Login-Google.svg"
                  }
                  alt="google"
                />
              </div>
              <div className="col-auto text-center">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icon/Login_apple.svg"
                  }
                  alt="apple"
                />
              </div>
            </div>
          </div> */}
          <div className="bottom pb-5">
            <p className="text-white">¿No tienes una cuenta?</p>
            <button
              type="button"
              className="bg-transparent p-2 text-white border-0"
              style={{ fontWeight: "bold" }}
              data-toggle="modal"
              data-target="#modal-centered"
            >
              <u>Conoce como ingresar</u>
            </button>
          </div>
          <div id="modal-centered" className="modal fade">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h6 className="modal-title text-center ml-4">
                    Solo los usuarios registrados pueden acceder a la app
                  </h6>
                  <button type="button" className="close" data-dismiss="modal">
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">cerrar</span>
                  </button>
                </div>
                <div className="modal-body text-center mx-3 mt-4">
                  <p style={{ fontSize: "20px" }}>
                    Pídele a tu administrador que te registre en nuestro sistema
                    para poder establecer tus credenciales de usuario
                  </p>
                </div>
                <div className="modal-footer text-center border-0 mb-4">
                  <button
                    type="button"
                    className="btn bg-blue mx-auto"
                    data-dismiss="modal"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Section>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    jwt: state.jwt,
    message: state.error,
  };
};

export default connect(mapStateToProps, { loginUserAction: loginUserStart })(
  Login
);

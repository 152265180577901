import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Section from "../layouts/Section";
import { servicePath } from "../../constants/defaultsValues";

const Resumen = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.jwt);

  const apiUrl = `${servicePath}/incidences`;
  const [selectedItem, setSelectedItem] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  async function fetchData() {
    axios
      .get(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSelectedItem(res.data);
        setIsLoaded(true);
      });
  }

  useEffect(() => {
    if (token) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Section
      title={"Incidencias"}
      withBack={true}
      noBorder={true}
      cancelTo="/incidences"
    >
      {isLoaded ? (
        <div className="transaction-area pt-5 resumen-incidences ">
          <div className="container pt-5">
            <div className="col-8 mx-auto text-center text-title">
              <i className="xlarge-icon icon-generada"></i>
            </div>
            <div className="col-10 mx-auto text-center mt-4">
              <h4>¡Incidencia generada!</h4>
            </div>
            <div className="col-12 mx-auto text-center my-4">
              <span>Resumen de tu incidencia</span>
            </div>
            <div className="col-12 mx-auto  px-3   text-center">
              <div className="card w-100 py-5 px-3">
                <p>{selectedItem.title}</p>
                <span>{selectedItem.service?.name}</span>
                <p>{moment(selectedItem.date).format("LL")}</p>
                <span>{selectedItem.area?.name}</span>
                {selectedItem.photo ? selectedItem.photo.map((p) => {
                  return (
                    <div className="my-2">
                      <img src={`${servicePath}${p.url}`} alt="cdsc" />
                    </div>
                  )
                }) : ''}
                <span className="mt-5 ">{selectedItem.description}</span>
              </div>
            </div>
            <div className="col-10 mx-auto py-5  ">
              <small>
                Tu incidencia fue generada con éxito y será Atendidas por el
                departamento correspondiente, mantente al tanto de tus
                notificaciones para conocer el proceso de resolución , si por
                algún motivo no atienden tu reporte en el tiempo deseado llama
                al teléfono:
              </small>
              <p className="mt-4 text-center">322 394 2342</p>
            </div>
            <div className="container text-center">
              <Link
                to="/incidences"
                className="btn btn-new-incidence col-8 mx-auto d-flex align-items-center justify-content-center text-center "
              >
                Terminar
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Section>
  );
};

export default Resumen;

import React, { useEffect, useState } from 'react';
import { servicePath } from '../../constants/defaultsValues';
import moment from "moment";
import { Link } from 'react-router-dom';
import Section from '../layouts/Section';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatter } from '../../helpers/Utils';

function Reservation() {
  const { id } = useParams();
  const token = useSelector((state) => state.jwt);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState({});
  const [icon, setIcon] = useState({});

  const fetchData = async () => {
    setLoading(true);
    axios.get(`${servicePath}/reservations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setReservation(res.data);
      axios.get(`${servicePath}/icons/${res.data.area.icon}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setIcon(res.data);
      });
      setLoading(false);
    })
  }

  useEffect(() => {
    fetchData();
  }, []);

  const deleteItem = (id) => {
    axios
      .put(`${servicePath}/reservations/${id}`,
      {
        cancelled: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        history.push('/reservations');
      });
  };

  return (
    <Section title={"Reservacion"} withBack={true}>
      {!loading ? (
        <div className="container pt-4 text-center">
          <div className="text-center">
            <img src="assets/reservations/reserva-generada.svg" alt="" />
          </div>
          <div
            className="col-8 mx-auto mt-4 text-center text-blue font-weight-bold"
            style={{ fontSize: '45px' }}
          >
            ¡Tu reserva!
          </div>
          <p className="text-gray my-4">
            Resumen de tu reservación
          </p>
          <div className="card-beige mx-3 py-5 text-center bg-beige">
            <div className="text-center mb-3">
              <img
                className="w-100 font-weight-bold"
                width="70"
                height="70"
                src={`/assets/icons/${icon?.fileName}`}
                alt="img"
              />
            </div>
            <div className="text-title text-center">
              {reservation.area?.name || ' '}
            </div>
            <h5 className="text-gray my-3 font-weight-bold">
              {moment(reservation.date).format("DD/MM/YYYY") || ' '}
            </h5>
            <div className="text-title text-center">
              {reservation.start?.substring(0, 5)} - {reservation.end?.substring(0, 5)}
            </div>
            <h5 className="text-gray mt-4 font-weight-bold">
              Cargo:
            </h5>
            <div className="text-title text-center">
              {formatter.format(reservation.blockArea?.cost || 0)}
            </div>
          </div>
          <p className="col-8 mx-auto mt-5 text-left">
            En breve recibirás un correo electrónico con la confirmación de tu reserva. El cargo de {formatter.format(reservation.blockArea?.cost || 0)} pesos por esta reservación se verá reflejada en tu estado de cuenta de este mes.
          </p>
          <p className="col-8 mx-auto mt-5 font-weight-bold text-left">
            Es importante mencionar que una vez que se genera una reservación esta deberá ser respetada, si no se pudiera utilizar por cualquier motivo es importante que libere el horario reservado para que otros puedan hacer uso de las áreas, con un mínimo de 4 horas de anticipación, en caso de que el área tenga un costo, si no se cancela antes del tiempo indicado se generará un cargo descrito por la reserva.
          </p>
          <div className="py-5">

          {
              moment(new Date()).format("DD/MM/YYYY") >= (moment(reservation.date).format("DD/MM/YYYY")) &&
              Number(reservation.start?.substring(0, 2)) - 4 <= Number(moment(new Date()).format('HH')) ?
              '' :
              (
                <button
                type="button"
                className="btn mx-auto btn-muted text-red col-8 mt-5 d-flex align-items-center justify-content-center"
                data-toggle="modal"
                data-target={`#modal-delete-${reservation.id}`}
              >
                Cancelar
              </button>
              )}
         
          </div>

          <div id={`modal-delete-${reservation.id}`} className="modal fade">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center my-4">
                  <p>Estas por cancelar tu reserva</p>
                  <div className="px-2 py-2 text-center">
                    <h5 className="pb-2 text-blue">{reservation.area?.name}</h5>
                    <span className="text-dark pb-0 font-weight-bold">
                      {moment(reservation.date).format("DD")}{" "}
                      {moment(reservation.date).format("MMMM")}{" "}
                      {moment(reservation.date).format("YYYY")}
                    </span>{" "}-{" "}
                    <span>
                      {reservation.start?.substring(0, 5)} a {reservation.end?.substring(0, 5)}
                    </span>
                    <br />
                    <span>
                      Precio: ${reservation.blockArea?.cost}
                    </span>
                  </div>
                  <h4 className="text-blue font-weight-bold">¿Deseas continuar?</h4>
                </div>
                <div className="modal-footer justify-content-between mx-3">
                  <button type="button" className="btn btn-shadow w-40 my-2" data-dismiss="modal">Cancelar</button>
                  <button onClick={() => deleteItem(reservation.id)} type="button" className="btn btn-red w-40 my-2" data-dismiss="modal">Confirmar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ' '
      )}

    </Section>
  )
}

export default Reservation;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '../../alertServices';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../layouts/Section';
import parse from 'html-react-parser';
import CivityIcon from '../svg/Civity';

function HowToPay() {
  const condominiumId = useSelector(state => state.condominium);
  const token = useSelector(state => state.jwt);
  const address = useSelector(state => state.address);
  const resident = useSelector(state => state.resident);
  const [condominium, setCondominium] = useState();
  const [instructions, setInstructions] = useState();
  const [addressData, setAddressData] = useState();

  async function fetchData() {
    axios
      .get(
        `${servicePath}/condominiums/${condominiumId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCondominium(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios.
      get(
        `${servicePath}/payment-instructions?condominium=${condominiumId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setInstructions(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
    const a = resident.addresses.find((a) => a.id === address);
    setAddressData(a);
  }, []);

  return (
    <Section
      title={"Pagos"}
      withBack={true}
      noBorder={true}
    // cancelTo="/payments/options"
    >
      <div className="card-beige bg-beige m-4 p-4 mt-5" >
        <h5 className="text-blue font-weight-bold text-center">
          {condominium?.name}
        </h5>
        <div className="mt-3">
          <div className="text-blue">
            Nombre unidad
          </div>
          <span className="text-blue font-weight-bold" style={{ fontSize: '12px' }}>
            {addressData?.name}
          </span>
        </div>
        <div className="mt-3">
          <div className="text-blue">
            Encargado de pagos
          </div>
          <span className="text-blue font-weight-bold" style={{ fontSize: '12px' }}>
            {resident.name} {resident.lastname}
          </span>
        </div>
      </div>
      <div className="font-weight-bold text-center">
        Para poder realizar tu pago sigue las <br /> instrucciones
      </div>
      {condominium?.electronicPayments ? (
        <>
          <a
            className="card-header collapsed d-flex align-items-center text-white bg-blue my-4 mx-4"
            data-toggle="collapse"
            href="#collapseOne"
            style={{ borderRadius: '10px' }}
          >
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11" cy="11" r="11" fill="white" />
              <path d="M12.6953 5.5625V17H11.25V7.36719L8.33594 8.42969V7.125L12.4688 5.5625H12.6953Z" fill="#2C2DA3" />
            </svg>
            <div className="card-title mb-0 ml-3 font-weight-bold">Pago Electrónico</div>
          </a>
          <div id="collapseOne" className="card-body collapse show pt-0 mt-0 mx-3 px-4" data-parent="#accordion">
            <p>
              Puedes pagar de manera electrónica a través de tus tarjetas, transferencia bancaria u OXXO con la opción de Pago Electrónico. Siempre y cuando tu condominio tenga la función configurada.
            </p>
          </div>
        </>
      ) : ''}
      {condominium?.paymentInstructions && instructions ? (
        <>
          <a className="card-header collapsed d-flex align-items-center text-white bg-blue my-4 mx-4" data-toggle="collapse" href="#collapseTwo" style={{ borderRadius: '10px' }}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11" cy="11" r="11" fill="white" />
              <path d="M15.3984 15.8125V17H7.95312V15.9609L11.6797 11.8125C12.138 11.3021 12.4922 10.8698 12.7422 10.5156C12.9974 10.1562 13.1745 9.83594 13.2734 9.55469C13.3776 9.26823 13.4297 8.97656 13.4297 8.67969C13.4297 8.30469 13.3516 7.96615 13.1953 7.66406C13.0443 7.35677 12.8203 7.11198 12.5234 6.92969C12.2266 6.7474 11.8672 6.65625 11.4453 6.65625C10.9401 6.65625 10.5182 6.75521 10.1797 6.95312C9.84635 7.14583 9.59635 7.41667 9.42969 7.76562C9.26302 8.11458 9.17969 8.51562 9.17969 8.96875H7.73438C7.73438 8.32812 7.875 7.74219 8.15625 7.21094C8.4375 6.67969 8.85417 6.25781 9.40625 5.94531C9.95833 5.6276 10.638 5.46875 11.4453 5.46875C12.1641 5.46875 12.7786 5.59635 13.2891 5.85156C13.7995 6.10156 14.1901 6.45573 14.4609 6.91406C14.737 7.36719 14.875 7.89844 14.875 8.50781C14.875 8.84115 14.8177 9.17969 14.7031 9.52344C14.5938 9.86198 14.4401 10.2005 14.2422 10.5391C14.0495 10.8776 13.8229 11.2109 13.5625 11.5391C13.3073 11.8672 13.0339 12.1901 12.7422 12.5078L9.69531 15.8125H15.3984Z" fill="#2C2DA3" />
            </svg>
            <div className="card-title mb-0 ml-3 font-weight-bold">Registra tu pago</div>
          </a>
          <div id="collapseTwo" className="card-body collapse show pt-0 mt-0 mx-3 px-4" data-parent="#accordion">
            {parse(instructions.instructions)}
          </div>
          <div className="text-center mt-3">
            <h6>
              CLABE
            </h6>
            <h4 className="text-blue">
              {instructions.clabe}
            </h4>
            <button
              type="button"
              className="btn btn-short mx-auto bg-blue mt-3 font-weight-normal px-4 d-block"
              onClick={async () => {
                await navigator.clipboard.writeText(instructions.clabe);
                Alert.success({ title: 'Clabe copiada!' })
              }}
            >
              Copiar CLABE
            </button>
          </div>
          <div className="text-center mt-5">
            <h6>
              Razón Social
            </h6>
            <h4 className="text-blue">
              {instructions.businessName}
            </h4>
            <button
              type="button"
              className="btn btn-short mx-auto bg-blue mt-3 font-weight-normal px-4 d-block"
              onClick={async () => {
                await navigator.clipboard.writeText(instructions.businessName);
                Alert.success({ title: 'Razón social copiada!' })
              }}
            >
              Copiar Razón Social
            </button>
          </div>
        </>
      ) : ''}
      <div className="text-center py-5">
        <CivityIcon />
      </div>
    </Section>
  );
}

export default HowToPay;

import React, { useEffect, useState } from "react";
import Section from "../layouts/Section";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { servicePath } from "../../constants/defaultsValues";
import axios from "axios";

const apiNotifications = `${servicePath}/notifications`;
function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  let history = useHistory();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.jwt);
  const [pageSize, setPageSize] = useState(10);

  const fetchNotificationsCount = () => {
    axios.get(`${apiNotifications}/count?resident=${user.resident.id}&viewed=false`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setNotificationsCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchNotifications = () => {
    axios.get(`${apiNotifications}?resident=${user.resident.id}&_limit=${pageSize}&_sort=published_at:DESC`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setNotifications(res.data);
      });
  };

  const deleteItem = (id) => {
    axios
      .delete(`${apiNotifications}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        fetchNotificationsCount();
        fetchNotifications();
      });
  };

  const handleClick = (n) => {
    axios.put(`${apiNotifications}/${n.id}`,
      {
        viewed: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return n.url

  };

  // const handleIcon = (type) => {
  // console.log("🚀 ~ file: index.js:70 ~ //handleIcon ~ type:", type)
  //   switch (type) {
  //     case 'incidence':
  //       return 'icon-incidencias';
  //     case 'charge':
  //       return 'icon-pagos';
  //     default:
  //       return ''
  //   }
  // }

  useEffect(() => {
    fetchNotificationsCount();
    fetchNotifications();
  }, [pageSize]);

  return (
    <Section allNotification={false} searchPopup={true} title={'Notification'}>
      <div className="transaction-area pd-top-36 mg-bottom-40">
        <div className="container">
          <div className="text-center">
            <h3 className="title text-blue">Inbox Notificaciones</h3>
          </div>
          <div className="text-center">
            <div className="bg-gray mb-2 p-2 d-inline-block border-rounded">
              Tienes <span className="text-blue font-weight-bold">{notificationsCount}</span> notificaciones
            </div>
          </div>
          {notifications.length ? notifications.map((n) => {
            return (
              <>
                <button
                  type="button"
                  onClick={() => handleClick(n)}
                  className={`card-notification ba-bill-pay-inner w-100 p-3 mt-3 ${n.viewed ? 'bg-transparent' : 'bg-gray'}`}
                >
                  {/* <div className="ba-single-bill-pay justify-content-">
                    <div className="thumb-lg">
                      <i className={handleIcon(n.type)} />
                    </div>
                  </div> */}
                  <div className="w-100">
                    <h5 className="d-flex align-items-center justify-content-center">
                      {n.title}
                    </h5>
                    <p className="text-justify">{n.message}</p>
                  </div>
                  <button type="button" className="btn bg-transparent border-0 p-0 m-0 d-flex align-items-center ml-2" data-toggle="modal" data-target={`#modal-delete-${n.id}`}>
                    <i className="xsmall-icon icon-delete pl-0"></i>
                  </button>
                  {/* MODAL DELETE */}
                  <div id={`modal-delete-${n.id}`} className="modal fade">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body text-center mt-4 mb-1">
                          <h4 className="text-blue font-weight-bold">¿Seguro que quieres eliminar esta notificacion?</h4>
                        </div>
                        <div className="modal-footer justify-content-between mx-3">
                          <button type="button" className="btn btn-shadow  my-2" data-dismiss="modal">Cancelar</button>
                          <button onClick={() => deleteItem(n.id)} type="button" className="btn btn-red my-2" data-dismiss="modal">Confirmar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </>
            )
          })
            : ''}
        </div>
        <div className="m-3 text-center">
          <button data-dismiss="modal" onClick={() => setPageSize(pageSize + 20)} className="btn bg-blue px-5">Ver más</button>
        </div>
      </div>
    </Section >
  );
}

export default Notification;

import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { servicePath } from '../../constants/defaultsValues';
import { formatter } from '../../helpers/Utils';
import Section from '../layouts/Section';


const url = `${servicePath}/transactions`;
const Payment = ({ selectedCard, resident }) => {
  const { id } = useParams();
  const token = useSelector((state) => state.jwt);
  const [ingress, setIngress] = useState({});

  async function fetchData() {
    axios.get(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIngress(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id])

  return ingress ? (
    <Section title="Pago" witBack>
      <div className="text-center pt-5">
        <img src="./assets/img/payments/Pago_exitoso.svg" alt="success" className="w-30" />
        <h1 className="text-blue" style={{ fontSize: '45px' }}>
          ¡Tu pago ha <br /> sido procesado <br /> con éxito!
        </h1>
        <div className="btn-short d-flex align-items-center justify-content-between px-4 mt-5 w-75 mx-auto">
          <span className="font-weight-normal">Pago realizado</span>
          <span>{formatter.format(Math.abs(ingress?.ingress))}.00</span>
        </div>
        <div className="mt-4 font-weight-bold">
          <div>
            <span>Fecha de pago: </span>
            {moment(ingress?.date).locale(false).format('DD MMMM YYYY')}
          </div>
          {/* <div>
            <span>Tarjeta: </span>
            XXXX-XXXX-XXXX-{selectedCard?.card?.last4}
          </div> */}
          <div>
            <span>Cliente: </span>
            {ingress?.resident?.name} {ingress?.resident?.lastname}
          </div>
          <div>
            <span>No. de Boleta: </span>
            {ingress?.idMovement}
          </div>
        </div>
        <Link
          to="/"
          className="btn btn-short mt-5 col-7 col-md-5 col-lg-3 mx-auto d-flex align-items-center justify-content-center text-center"
        >
          Terminar
        </Link>
      </div>
    </Section>
  ) : ''
};

export default Payment;

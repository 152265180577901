import React, { useState } from 'react';
import moment from 'moment';
import Arrow from '../svg/Arrow';
import Camera from '../svg/Camera';
import CloseRedIcon from '../svg/CloseRedIcon';

const Comments = ({ data, onSubmit, servicePath, currentUser }) => {
  const [image, setImage] = useState(null);
  const [newComment, setNewComment] = useState('');
  return (
    <>
      {/* <div className="p-0 pb-3 overflow-auto d-fle"> */}
        {/* {data.map((comment) => {
          console.log("🚀 ~ file: Comments.js ~ line 14 ~ {data.map ~ comment", comment)
          return comment.user !== currentUser ? (
            <div
              className="d-flex justify-content-start mt-3 ml-2"
              key={`comment-${comment.id}`}
            >
              <div
                className="p-3 w-75"
                style={{ backgroundColor: '#F2F2F2', borderRadius: '10px' }}
              >
                <span className="text-primary font-weight-bold">
                  {comment.user.username}
                </span>
                <p>{comment.comment}</p>
                <p>{comment.image ? comment.image?.url : ''}</p>
                {comment.image && (
                  <a
                    href={`${servicePath}${comment.image?.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      style={{ width: '300px', height: '300px' }}
                      className="mb-3"
                    >
                      <img
                        src={`${servicePath}${comment.image?.url}`}
                        alt={comment.image?.name}
                        className="w-100 h-100"
                        style={{ objectFit: 'cover', borderRadius: '15px' }}
                      />
                    </div>
                  </a>
                )}
                <div className="d-flex justify-content-end">
                  <span className="text-muted">
                    {moment(comment.date).format('DD/MM/YYYY, hh:mm a')}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="d-flex justify-content-end mt-3 mr-2"
              key={`comment-${comment.id}`}
            >
              <div
                className="p-3 w-75"
                style={{ backgroundColor: '#DFE0F1', borderRadius: '10px' }}
              >
                <span className="text-primary font-weight-bold">
                  {comment.user?.username}
                </span>
                <p>{comment.comment}</p>
                {comment.image && (
                  <a
                    href={`${servicePath}${comment.image?.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      style={{ width: '300px', height: '300px' }}
                      className="mb-3"
                    >
                      <img
                        src={`${servicePath}${comment.image?.url}`}
                        alt={comment.image?.name}
                        className="w-100 h-100"
                        style={{ objectFit: 'cover', borderRadius: '10px' }}
                      />
                    </div>
                  </a>
                )}
                <div className="d-flex justify-content-end ">
                  <span className="text-muted">
                    {' '}
                    {moment(comment.date).format('DD/MM/YYYY, hh:mm a')}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
      <div className="mt-3">
      {image && (
          <div>
            <span className="text-primary ml-3 pb-1">
              <u>{image.name}</u>
            </span>
            <button
              type="button"
              size="sm"
              className="btn m-0 bg-transparent h-50 p-0 ml-2"
              style={{ lineHeight: '0' }}
              onClick={() => setImage(null)}
            >
              <CloseRedIcon />
            </button>
          </div>
        )}
      {/* <div className="w-100 d-flex align-items-center" style={{ heigth: '50px' }}> */}
        <form className="w-100 d-flex align-items-center" id="form-comments" >
          <div className="p-0 pl-3">
            <input
              type="textarea"
              id="comment"
              name="comment"
              placeholder="Responder..."
              onChange={(e) => setNewComment(e.target.value)}
              value={newComment}
              style={{
                resize: 'none',
                borderRadius: '5px',
                outline: 'none',
              }}
              className="border border-primary p-2 w-100"
              rows="1"
            />
            {/* {errors.comment && touched.comment && (
              <div>{errors.comment}</div>
            )}
            {errors.image && (
              <div className="invalid-feedback d-block">
                {errors.image}
              </div>
            )} */}
          </div>
          <div className="p-0 ml-2">
            <div>
              <label
                htmlFor="image"
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '40px',
                  cursor: 'pointer',
                }}
                className="d-flex btn-area align-items-center justify-content-center p-0 m-0 border border-primary"
              >
                <Camera />
              </label>
              <input
                  id="image"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setImage(e.currentTarget.files[0])}
                  className="d-none"
                />
            </div>
          </div>
          <div className="p-0 ml-2">
            <button
              // form="form-comments"
              type="button"
              onClick={() => {
                onSubmit({ comment: newComment, image });
                setImage(null);
                setNewComment('');
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '40px',
              }}
              className="d-flex align-items-center btn-area bg-blue border border-primary justify-content-center p-0"
            >
              <Arrow />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Comments;

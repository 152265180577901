import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import Section from "../layouts/Section";
import { servicePath } from "../../constants/defaultsValues";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';

const apiUrl = `${servicePath}/news`;
const apiFavoriteNews = `${servicePath}/favorite-news`;
const News = () => {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.jwt);
  const [favoriteNews, setFavoriteNews] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const { id } = useParams();
  async function getFavorites() {
    axios
      .get(`${apiFavoriteNews}?_limit=-1&&user=${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFavoriteNews(res.data);
      });
  }
  async function fetchData() {
    getFavorites();
    axios
      .get(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSelectedItem(res.data);
        setIsLoaded(true);
        axios
          .put(`${apiUrl}/${id}`,
            {
              views: res.data.views ? res.data.views + 1 : 1
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
      });
  }

  const onClickFavorites = (id, action) => {
    if (action === "add") {
      const data = { new: id, user: user.id };
      axios
        .post(`${apiFavoriteNews}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => getFavorites());
    } else {
      const idItem = favoriteNews.find((favorite) => favorite.new.id === id).id;
      axios
        .delete(`${apiFavoriteNews}/${idItem}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => getFavorites());
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <Section title={"Noticias"} withBack={true}>
      {isLoaded ? (
        <div className="blog-details-area container-show-new">
          {selectedItem.image && (
            <img
              className="w-100"
              src={`${servicePath}${selectedItem.image?.url}`}
              alt="img"
            />
          )}
          <div className="blog-details-inner">
            <div className="container">
              <div className="details">
                <div className="row  mb-4">
                  <div className="col-8">
                    <h6 className="pb-0 mb-0">{selectedItem.title}</h6>
                    <small>Creado por {selectedItem.author}</small> <br />
                    <span>{moment(selectedItem.date).format("LL")}</span>
                  </div>
                  <div className="col-3 d-flex justify-content-end p-0">
                    {favoriteNews &&
                      favoriteNews.find(
                        (favorite) => favorite.new?.id === selectedItem.id
                      ) ? (
                      <button
                        className="bg-transparent border-0 p-0  amount"
                        onClick={() =>
                          onClickFavorites(selectedItem.id, "delete")
                        }
                      >
                        <i className="large-icon icon-favoritas" />
                      </button>
                    ) : (
                      <button
                        className="bg-transparent border-0 p-0  amount"
                        onClick={() => onClickFavorites(selectedItem?.id, "add")}
                      >
                        <i className="large-icon icon-no-favoritas" />
                      </button>
                    )}
                  </div>
                </div>
                {selectedItem.description ? (
                  <div>
                    <p>{parse(selectedItem?.description)}</p>
                  </div>
                ) : ''}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Section>
  );
};

export default News;

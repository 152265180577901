import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { servicePath } from "../../constants/defaultsValues";
import { formatter } from "../../helpers/Utils";
import Section from "../layouts/Section";

const apiUrl = `${servicePath}/quotas`;
const apiGB = `${servicePath}/transactions`;
function PaymentsHistory() {
  const address = useSelector(state => state.address);
  const condominium = useSelector(state => state.condominium);
  const token = useSelector((state) => state.jwt);
  const [isLoaded, setIsLoaded] = useState(true);
  const [quotas, setQuotas] = useState([]);
  const [GB, setGB] = useState([]);
  const [selectedQuotas, setSelectedQuotas] = useState([]);
  const [pendingTotal, setPendingTotal] = useState(0);
  const [expiredTotal, setExpiredTotal] = useState(0);
  const [payoutTotal, setPayoutTotal] = useState(0);
  const [chargesTotal, setChargesTotal] = useState(0);

  async function fetchData() {
    setIsLoaded(false);
    axios
      .get(
        `${apiUrl}?address=${address}&isActive=true&canceled=false&isCancellation=false&pending_gt=0&_limit=-1&_sort=expirationDate:DESC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setQuotas(res.data);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(true);
      });
    axios
      .get(
        `${apiGB}?address=${address}&canceled=false&verified=true&_limit=-1&_sort=id:DESC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setGB(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  }

  useEffect(() => {
    let chargesCount = 0;
    let payoutCount = 0;
    let pendingCount = 0;
    let expiredCount = 0;
    quotas.forEach((row) => {
      chargesCount += row.amount;
      if (row.pending) {
        if (moment(new Date()).isAfter(row.expirationDate)) {
          expiredCount += row.pending;
        } else {
          pendingCount += row.pending;
        }
      }
    });
    GB.forEach((row) => {
      payoutCount += row.amount;
    })
    setPendingTotal(Math.abs(pendingCount));
    setExpiredTotal(Math.abs(expiredCount));
    setPayoutTotal(Math.abs(payoutCount));
    setChargesTotal(Math.abs(chargesCount));
  }, [quotas, GB]);

  useEffect(() => {
      fetchData();
  }, []);
  return (
    <Section title={"Historial de pagos"} withBack>
      {isLoaded ? (
        <div>
          <div className="container py-5 mb-5">
            <h1 className="text-blue text-center mb-5">
              Historial de pagos
            </h1>
            <div className="d-flex justify-content-center mb-3">
              <div className="d-flex w-100 justify-content-center align-items-center card-incidences py-4 bg-beige mr-3">
                <div className="text-center">
                  <h3 className="text-blue mb-0">{formatter.format(chargesTotal)}</h3>
                  <h6 className="text-blue font-weight-normal">
                    Cargos Totales
                  </h6>
                </div>
              </div>
              <div className="d-flex w-100 justify-content-center align-items-center card-incidences py-4 bg-green">
                <div className="text-center">
                  <h3 className="text-blue mb-0">{formatter.format(payoutTotal)}</h3>
                  <h6 className="text-blue font-weight-normal">
                    Abonos Totales
                  </h6>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mb-3">
              <div className="d-flex w-100 justify-content-center align-items-center card-incidences py-4 bg-beige mr-3">
                <div className="text-center">
                  <h3 className="text-red mb-0">{formatter.format(pendingTotal)}</h3>
                  <h6 className="text-red font-weight-normal">
                    Adeudo Actual
                  </h6>
                </div>
              </div>
              <div className="d-flex w-100 justify-content-center align-items-center card-incidences py-4 bg-red">
                <div className="text-center">
                  <h3 className="text-white mb-0">{formatter.format(expiredTotal)}</h3>
                  <h6 className="text-white font-weight-normal">
                    Vencido
                  </h6>
                </div>
              </div>
            </div>
            {/* FILTROS */}
            {/* <div className="d-flex justify-content-center my-5">
            <div className="col-5 div-select bg-beige mr-3 p-2">
              <select className="form-select w-100 bg-beige border-red" defaultValue="">
                <option className="">
                  Todo
                </option>
              </select>
            </div>
            <div className="col-5 div-select bg-beige p-2">
              <select className="form-select w-100 bg-beige" defaultValue="">
                <option>
                  Todo
                </option>
              </select>
            </div>
          </div> */}
            <div className="container container-news mt-5">
            </div>
            <div className="transaction-area pt-3 section-incidences mb-5">
              <form className="transaction-inner row align-items-stretch">
                {GB.length ? GB.map((payment, i) => (
                  <>
                    {/* <div className="col-12">
                    <div className="text-center">
                      <h3 className="title">Enero 2021</h3>
                    </div>
                  </div> */}
                    {/* <Link to={`payment/${payment.id}`}> */}
                      <PaymentsCard
                        key={i}
                        payment={payment}
                        setSelectedQuotas={setSelectedQuotas}
                        selectedQuotas={selectedQuotas}
                      />
                    {/* </Link> */}
                  </>
                )) : (
                  <div className="text-center text-blue w-100 mx-auto mt-5">
                    <h3 className="title text-blue">
                      No hay pagos registrados
                    </h3>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Section>
  );
}

const PaymentsCard = ({ payment }) => {
  return (
    <>
      <Link to={`payment/${payment.id}`} className="card-area w-100 mb-2" style={{ backgroundColor: '#f8f7f7' }}>
        <div className="col-12 ">
          <div className="details">
            <h5 className="w-100">{payment.name}</h5>
          </div>
          <div className="mt-3 mb-0 pb-0 d-flex justify-content-between">
            <div>
              <h6 className="text-blue font-weight-normal">{moment(payment.date).format('DD/MM/YYYY')}</h6>
            </div>
            <div>
              <h6 className="text-red font-weight-normal">Cargo</h6>
            </div>
            <div>
              <h5 className="text-red font-weight-bold">{formatter.format(Math.abs(payment.amount))}.00</h5>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default PaymentsHistory;

import React from 'react';

const Camera = () => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.677 2.52381L9.52058 0.826145C9.47137 0.752333 9.39756 0.703125 9.29914 0.703125H5.36252C5.2641 0.703125 5.19029 0.752333 5.14108 0.826145L3.9847 2.52381H1.08143C0.933811 2.52381 0.810791 2.64683 0.810791 2.79446V11.1598C0.810791 11.2828 0.933811 11.4058 1.08143 11.4058H13.6048C13.7524 11.4058 13.8755 11.2828 13.8755 11.1352V2.79446C13.8755 2.64683 13.7524 2.52381 13.6048 2.52381H10.677Z"
        stroke="#2C2DA3"
        strokeMiterlimit="10"
      />
      <path
        d="M7.33079 9.46359C8.89346 9.46359 10.1602 8.1968 10.1602 6.63414C10.1602 5.07148 8.89346 3.80469 7.33079 3.80469C5.76813 3.80469 4.50134 5.07148 4.50134 6.63414C4.50134 8.1968 5.76813 9.46359 7.33079 9.46359Z"
        stroke="#2C2DA3"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Camera;

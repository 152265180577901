import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

const AuthRoute = (props) => {
  const { isAuthUser, type, state } = props;
  if (!state.jwt) return <Redirect to="/login" />;
   else if (type === "private" && !isAuthUser) return <Redirect to="/" />;

  return <Route {...props} />;
};

const mapStateToProps = (state) => ({
  state,
});

export default connect(mapStateToProps)(AuthRoute);

import axios from 'axios';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../layouts/Section';
import io from 'socket.io-client';


const apiUpload = `${servicePath}/upload`;
function RegisterPayment() {
  const condominiumId = useSelector(s => s.condominium);
  const user = useSelector(s => s.user);
  const address = useSelector(s => s.address);
  const resident = useSelector(s => s.resident);
  const token = useSelector(s => s.jwt);
  const [isLoaded, setIsLoaded] = useState(true);
  const [errors, setErrors] = useState({
    title: null,
    departament: null,
    area: null,
    service: null,
    description: null,
  });
  const [isSend, setIsSend] = useState(false);
  const [name, setName] = useState('');
  const [unit, setUnit] = useState('');
  const [date, setDate] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [id, setId] = useState('');
  const [comment, setComment] = useState('');
  const [amount, setAmount] = useState('');
  const [image, setImage] = useState(null);

  async function fetchData() {
    await axios.get(
      `${servicePath}/addresses/${address}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUnit(res.data);
        setName(`${res.data?.name} ${user.resident?.name} ${user.resident?.lastname}`);
      })
      .catch((err) => {
        console.log(err);
      })
  };

    const upload = (file) => {
    const imgs = image ? image : [];
    if (file) {
      const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
      const size = bytesToMegaBytes(file.size);
      if (
        size < 100 &&
        (file.type === "image/jpeg" || file.type === "image/png")
      ) {
        imgs.push({
          file: file,
          name: file.name,
          url: URL.createObjectURL(file),
        });
        setImage(imgs[0]);
      }
    }
  };

  const uploadFile = async (file, id) => {
    const bodyFormData = new FormData();
    bodyFormData.append("files", file, `${file.name}`);
    bodyFormData.append("ref", "transactions");
    bodyFormData.append("refId", id);
    bodyFormData.append("field", "image");
    const saveDocument = await axios({
      method: "post",
      url: `${apiUpload}`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return saveDocument;
  };

  const validateRequired = (value) => {
    let error = null;
    if (!value) {
      error = "Campo obligatorio";
    }
    return error;
  };
  const validate = () => {
    const validUnit = validateRequired(address);
    const validDate = validateRequired(date);
    const validPaymentMethod = validateRequired(paymentMethod);
    const validAmount = validateRequired(amount);
    setErrors({
      unit: validUnit,
      date: validDate,
      paymentMethod: validPaymentMethod,
      amount: validAmount,
    });
    return !validUnit && !validDate && !validPaymentMethod && !validAmount ? true : false;
  };

  const handlePushNotification = () => {
    const socket = io.connect(servicePath);
    socket.emit("notification:register-payment", {
      username: `${resident.name} ${resident.lastname}`,
      condominium: condominiumId,
    });
  };

  const onSubmit = () => {
    setIsLoaded(false);
    const data = {
      type: 'Ingress',
      condominium: condominiumId,
      address,
      name,
      date: moment(date),
      amount: amount
        ? Number(
          typeof amount === 'string'
            ? amount.replace(/\$|,/g, '')
            : amount
        )
        : null,
      idCharge: id,
      editor: user.id,
      resident: user.resident.id,
      canceled: false,
      verified: false,
      comment,
      paymentMethod,
    };
    axios
      .post(
        `${servicePath}/transactions`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (image) {
          uploadFile(image.file, res.data.id);
        }
        setIsSend(true);
        setIsLoaded(true);
        handlePushNotification(res.data.id);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Section
      title={"Registra tu pago"}
      withBack={true}
      noBorder={true}
    // cancelTo="/payments"
    >
      {isLoaded ? (
        <>
          {!isSend ? (
            <form onSubmit={onSubmit} className="transaction-area pt-5">
              <div className="text-center">
                <Link
                  to="/payments/how-to-pay"
                  className="btn bg-blue font-weight-bold"
                >
                  ¿Cómo pagar?
                </Link>
              </div>
              <div className="col-12 col-lg-8 mx-auto section form pt-5">
                <div className=" mb-4">
                  <p>Unidad</p>
                  <input
                    id="unit"
                    className="w-100 bg-beige border-0"
                    placeholder={name}
                    values={unit.id}
                    disabled
                  />{" "}
                  {errors.unit ? (
                    <span className="text-danger text-center pt-5">{errors.unit}</span>
                  ) : null}
                </div>
                <div className="my-4">
                  <div className="">
                    <p>Fecha de pago*</p>
                    <div className="col-12 bg-beige p-0">
                      <input
                        className="w-100 bg-beige border-0"
                        type="date"
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                    {errors.date ? (
                      <span className="text-danger">{errors.date}</span>
                    ) : null}
                  </div>
                </div>
                <div className="my-4 align-items-end">
                  <div className="">
                    <p>Método de pago*</p>
                    <div className="div-select col-12 bg-beige">
                      <select
                        className="form-select pl-0 pr-2 w-100 bg-beige"
                        // defaultValue=""
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option value="">Seleccionar</option>
                        <option value="Efectivo">Efectivo</option>
                        <option value="Tarjeta">Tarjeta</option>
                        <option value="Transferencia">Transferencia</option>
                        <option value="Cheque">Cheque</option>
                      </select>
                    </div>
                    {errors.paymentMethod ? (
                      <span className="text-danger">{errors.paymentMethod}</span>
                    ) : null}
                  </div>
                </div>
                <div className="my-4">
                  <div>
                    <p>Monto*</p>
                    <input
                      className="w-100 bg-beige border-0"
                      type="number"
                      placeholder="$0.00"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    {errors.amount ? (
                      <span className="text-danger">{errors.amount}</span>
                    ) : null}
                  </div>
                </div>
                <div className="my-4">
                  <div>
                    <p>ID Transacción/Aprobación</p>
                    <input
                      className="w-100 bg-beige border-0"
                      type="text"
                      onChange={(e) => setId(e.target.value)}
                    />
                    {errors.id ? (
                      <span className="text-danger">{errors.id}</span>
                    ) : null}
                  </div>
                </div>
                <div className="my-4 align-items-end">
                  <div className="ml-auto">
                    <p>Comentarios</p>
                  </div>
                  <div className="">
                    <textarea
                      style={{ height: "240px" }}
                      className="form-control w-100 bg-beige border-0 p-4"
                      onChange={(e) => setComment(e.target.value)}
                    />
                    {errors.comment ? (
                      <span className="text-danger">{errors.comment}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="container text-center my-4">
                <label
                  style={{ display: 'revert' }}
                  htmlFor="picture"
                  className="btn btn-short p-3"
                >
                  {image ? image.name.length > 20 ? `${image.name.substring(0, 20)}` : image.name : 'Adjunta una imagen'}
                </label>
                <input
                  id="picture"
                  className="d-none"
                  accept="image/png, image/jpg"
                  type="file"
                  onChange={(e) => upload(e.target.files[0])}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between border-top text-center py-3 mt-4 px-4" style={{ border: '5px', border: 'black' }}>
                <Link to="/payments/options">
                  <button
                    type="button"
                    className="btn btn-short bg-beige text-dark"
                  >
                    Cancelar
                  </button>
                </Link>
                <button
                  type="button"
                  onClick={() => {
                    console.log(validate());
                    if (validate()) {
                      onSubmit();
                    }
                  }}
                  className="btn btn-short"
                >
                  Aceptar
                </button>
              </div>
            </form>
          ) : (
            <div className="text-center pt-5 transaction-area">
              <div className="h-100">
                <img src="./assets/img/payments/Pago_exitoso.svg" alt="success" className="w-30" />
                <h1 className="text-blue" style={{ fontSize: '45px' }}>
                  ¡Pago <br /> enviado!
                </h1>
                <h6 className="mt-4 text-blue ">
                  Haz enviado tu comprobante
                  <br />
                  de pago correctamente
                </h6>
                <p className="mt-4">
                  A la brevedad se verificará la información
                  <br />
                  para actualizar el estado de tus cargos.
                </p>
                <h6 className="mt-3">
                  ID de transacción
                </h6>
                <h6 className="text-blue">
                  0000-0000
                </h6>
              </div>
              <div className="d-flex align-items-center justify-content-between border-top text-center py-3 px-4" style={{ border: '5px', border: 'black', marginTop: '180px' }}>
                <Link to="/payments/options">
                  <button
                    type="button"
                    className="btn btn-short bg-beige  text-dark"
                  >
                    Cancelar
                  </button>
                </Link>
                <Link to="/payments">
                  <button
                    type="button"
                    className="btn btn-short"
                  >
                    Terminar
                  </button>
                </Link>
              </div>
            </div>
          )}
        </>
      ) : (
        ''
      )}
    </Section>
  );
}

export default RegisterPayment

import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { servicePath } from '../../../constants/defaultsValues';
import { formatter } from '../../../helpers/Utils';



const url = `${servicePath}/quotas`;
const PaymentSuccess = ({ selectedCard, resident }) => {
  const { id } = useParams();
  const token = useSelector((state) => state.jwt);
  const [quota, setQuota] = useState({});
  // const history = useHistory();

  async function fetchData() {
    axios.get(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
  })
    .then((res) => {
      setQuota(res.data);
    })
    .catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    fetchData();
  }, [])
  

  return quota ? (
    <div className="text-center pt-5">
      <img src="./assets/img/payments/Pago_exitoso.svg" alt="success" className="w-30" />
      <h1 className="text-blue" style={{ fontSize: '45px' }}>
        ¡Gracias <br />por tu pago!
      </h1>
      <p className="mt-4">
        El pago en linea ha sido aplicado
        <br />
        éxitosamente a tu tarjeta.
      </p>
      <div className="btn-short d-flex align-items-center justify-content-between px-4 mt-5 w-75 mx-auto">
        <span className="font-weight-normal">Pago realizado</span>
        <span>{formatter.format(Math.abs(quota.pending))}.00</span>
      </div>
      <div className="mt-4 font-weight-bold">
        <div>
          <span>Fecha de pago: </span>
          {moment(new Date()).locale(false).format('DD MMMM YYYY')}
        </div>
        <div>
          <span>Tarjeta: </span>
          XXXX-XXXX-XXXX-{selectedCard?.card?.last4}
        </div>
        <div>
          <span>Cliente: </span>
          {resident.name} {resident.lastname}
        </div>
        <div>
          <span>No. de Boleta: </span>
          {quota.idMovement}
        </div>
      </div>
      <Link
        to="/"
        className="btn btn-short mt-5 col-7 col-md-5 col-lg-3 mx-auto d-flex align-items-center justify-content-center text-center"
      >
        Terminar
      </Link>
    </div>
  ) : ''
};

export default PaymentSuccess;

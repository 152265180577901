import React from "react";
import Routes from "./Routes";
import "./App.css";

// const socket = io(servicePath);
const App = () => {
  return <Routes />;
};

export default App;

// import { combineReducers } from "redux";
import authUser from "./auth/reducer";

// const reducers = combineReducers({
//   address,
//   authUser,
// });
// console.log("🚀 ~ file: reducers.js ~ line 9 ~ reducers", reducers)

export default authUser;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Section from "../layouts/Section";
import { servicePath } from "../../constants/defaultsValues";
import 'react-calendar/dist/Calendar.css';
import { Link } from "react-router-dom";
import { formatter } from "../../helpers/Utils";
import moment from "moment";
import Barcode from 'react-barcode';
import { Alert } from "../../alertServices";
const apiQuotas = `${servicePath}/quotas`;
const apiCards = `${servicePath}/payment-methods/stripe`;

const ElectronicPay = () => {
  const resident = useSelector((state) => state.resident);
  const user = useSelector((state) => state.user);
  const address = useSelector((state) => state.address);
  const condominium = useSelector((state) => state.condominium);
  const token = useSelector((state) => state.jwt);
  const [item, setItem] = useState({});
  const [quotas, setQuotas] = useState(null);
  const [cards, setCards] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedQuotas, setSelectedQuotas] = useState([]);
  const [method, setMethod] = useState('');
  const [store, setStore] = useState('');
  const [pendingTotal, setPendingTotal] = useState(0);
  const [expiredTotal, setExpiredTotal] = useState(0);
  const [payTotal, setPayTotal] = useState(false);
  const [paySuccess, setPaySuccess] = useState(false);
  const [payFail, setPayFail] = useState(false);
  const [spei, setSpei] = useState(null);
  const [oxxo, setOxxo] = useState(null);
  const [showAllCards, setShowAllCards] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [today] = useState(new Date());

  async function fetchData() {
    const firstRequest = axios.get(
      `${apiQuotas}?address=${address}&isActive=true&canceled=false&isCancellation=false&paid=false&_limit=-1&_sort=id:DESC`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const secondRequest = axios.get(
      `${apiCards}/${user.id}/${resident.addresses.find((a) => a.id === address).condominium}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const [firstResponse, secondResponse] = await Promise.all([
      firstRequest,
      secondRequest,
    ]);
    setQuotas(firstResponse.data);
    setCards(secondResponse.data);
    setSelectedCard(secondResponse.data?.paymentMethods.find((c) => c.id === secondResponse.data?.defaultPaymentMethod) || null);
    setIsLoaded(true);
  }

  const getSpei = async () => {
    setIsLoaded(false);
    const addre = resident.addresses.find((r) => r.id === address);
    await axios
      .post(
        `${servicePath}/quotas/spei-pay`,
        {
          quotas: selectedQuotas,
          payTotal,
          user,
          address: addre,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSpei(res.data?.next_action.display_bank_transfer_instructions);
        setItem(res.data);
        setMethod('spei');
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  };

  const getOxxo = async () => {
    setIsLoaded(false);
    await axios
      .post(
        `${servicePath}/quotas/oxxo-pay`,
        {
          quotas: selectedQuotas,
          payTotal,
          user,
          address,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOxxo(res.data?.next_action?.oxxo_display_details || null);
        setItem(res.data);
        setStore('oxxo');
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  };

  const onSelectQuotas = async e => {
    let newArray = [...selectedQuotas, e.id];
    if (selectedQuotas.includes(e.id)) {
      newArray = newArray.filter((q) => q !== e.id);
    }
    setSelectedQuotas(newArray);
  };

  const selectAllQuotas = () => {
    if (selectedQuotas.length !== quotas.length) {
      const array = quotas.map((q) => {
        return q.id
      });
      setSelectedQuotas(array);
    } else {
      setSelectedQuotas([]);
    }
  }

  const payWithCard = () => {
    axios.post(`${servicePath}/quotas/card-pay`,
      {
        quotas: selectedQuotas,
        payTotal,
        user,
        address,
        condominium,
        resident: resident.id,
        pm: selectedCard,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        setPaymentIntent(res.data);
        if (res.data.status === 'succeeded') {
          setPaySuccess(true);
        } else {
          setPayFail(true);
        }
      })
      .catch((err) => {
        // history.push(`/payment-fail/${}`);
        console.log(err);
        setPayFail(true);
      })
  }

  const onSubmit = () => {
    switch (method) {
      case 'card':
        payWithCard();
        break;
      case 'spei':
        getSpei();
        break;
      case 'oxxo':
        getOxxo();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    moment.locale('es-mx');
    fetchData();
  }, []);

  useEffect(() => {
    let pendingCount = 0;
    let expiredCount = 0;
    if (quotas) {
      quotas.forEach((row) => {
        if (row.pending) {
          if (moment(new Date()).isAfter(row.expirationDate)) {
            expiredCount += row.pending;
          } else {
            pendingCount += row.pending;
          }
        }
      });
    }
    setPendingTotal(Math.abs(pendingCount));
    setExpiredTotal(Math.abs(expiredCount));
  }, [quotas]);

  useEffect(() => {
    let totalPay = 0;
    if (quotas) {
      quotas.forEach((q) => {
        if (selectedQuotas.includes(q.id)) {
          totalPay += Math.abs(q.pending);
        }
      });
    }
    setPayTotal(totalPay);
  }, [selectedQuotas]);

  useEffect(() => {
    if (showAllCards) {

    }
  }, [showAllCards]);

  return (
    <Section
      title={"Pagar"}
      withBack={true}
      noBorder={true}
      cancelTo="/payments"
    >
      {isLoaded ? (
        <div className="transaction-area pt-5">
          {method === '' && (
            <PaymentMethods
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              getSpei={getSpei}
              getOxxo={getOxxo}
              setMethod={setMethod}
              setStore={setStore}
              store={store}
              cards={cards}
              expiredTotal={expiredTotal}
              pendingTotal={pendingTotal}
              showAllCards={showAllCards}
              setShowAllCards={setShowAllCards}
            />
          )}
          {method && !spei && !oxxo && !paySuccess && !payFail && (
            <SelectQuota
              selectedCard={selectedCard}
              onSelectQuotas={onSelectQuotas}
              selectAllQuotas={selectAllQuotas}
              selectedQuotas={selectedQuotas}
              quotas={quotas}
              setPaySuccess={setPaySuccess}
              setMethod={setMethod}
              method={method}
              pendingTotal={pendingTotal}
              cards={cards}
              payTotal={payTotal}
              onSubmit={onSubmit}
              today={today}
            />
          )}
          {method === 'spei' && spei && (
            <Spei spei={spei} pendingTotal={pendingTotal} expiredTotal={expiredTotal} />
          )}
          {/* {method === 'store' && store && !oxxo && !generatedCode && (
            <Store
              store={store}
              quotas={quotas}
              oxxo={oxxo}
              item={item}
              setSelectedQuota={setSelectedQuotas}
              generateCode={generateCode}
            />
          )} */}
          {oxxo && method === 'oxxo' && (
            <OxxoCode oxxo={oxxo} item={item} payTotal={payTotal} />
          )}
          {paySuccess && (
            <PaySuccess
              selectedQuotas={selectedQuotas}
              selectedCard={selectedCard}
              resident={resident}
              payTotal={payTotal}
              paymentIntent={paymentIntent}
            />
          )}
          {payFail && (
            <PayFail
              selectedQuotas={selectedQuotas}
              selectedCard={selectedCard}
              paymentIntent={paymentIntent}
              resident={resident}
            />
          )}
        </div>
      ) : (
        ''
      )}
    </Section>
  );
};



const PaymentMethods = ({
  setSelectedCard,
  selectedCard,
  setMethod,
  cards,
  pendingTotal,
  expiredTotal,
  showAllCards,
  setShowAllCards,
}) => {
  return (
    <div className="container pb-5">
      <div className="d-flex justify-content-center py-4">
        <div className="d-flex w-100 justify-content-center align-items-center card-incidences bg-beige mr-3">
          <div className="text-center">
            <h3 className="text-red mb-0">{formatter.format(pendingTotal)}</h3>
            <h6 className="text-red font-weight-normal">
              Adeudo Actual
            </h6>
          </div>
        </div>
        <div className="d-flex w-100 justify-content-center py-4 card-incidences bg-red">
          <div className="text-center">
            <h3 className="text-white mb-0">{formatter.format(expiredTotal)}</h3>
            <h6 className="text-white font-weight-normal">
              Vencido
            </h6>
          </div>
        </div>
      </div>
      <div>
        <h6 className="text-blue text-center font-weight-normal pb-3">Selecciona metodo de pago</h6>
        {/* TARJETAS */}
        <div className="card-incidences bg-beige p-3">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-blue">Tarjeta</h4>
          </div>
          <form>
            {cards && cards.paymentMethods ? cards.paymentMethods.map((c, i) => {
              return (
                <>
                  {selectedCard && c.id === selectedCard.id ? (
                    <label htmlFor="card" className="w-100">
                      <div className="text-white py-3 px-4 mb-3" style={{ backgroundColor: '#868686', borderRadius: '10px' }}>
                        <div className="d-flex align-items-center ml-2 mb-4">
                          <div className="mr-2">
                            <input type="radio" checked={true} onChange={() => { }} name="radio" />
                            <span className="checkmark" />
                          </div>
                          <div>
                            <h4 className="text-white">{selectedCard.name}</h4>
                            <h6 className="text-white font-weight-normal">XXXX-XXXX-XXXX-{selectedCard.card?.last4} {selectedCard.id === cards.defaultPaymentMethod && '(Principal)'}</h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mx-2 mb-2">
                          <div>
                            <div className="text-12">
                              Vencimiento
                            </div>
                            <div className="text-16">
                              {selectedCard.card?.exp_month <= 9 ? `0${selectedCard.card?.exp_month}` : selectedCard.card?.exp_year}/{selectedCard.card?.exp_year?.toString()?.substring(2)}
                            </div>
                          </div>
                          {selectedCard.metadata.name ? (
                            <div>
                              <div className="text-12">
                                Nombre
                              </div>
                              <div className="text-16">
                                <span>{selectedCard.metadata.name}</span>
                              </div>
                            </div>
                          ) : ''}
                          <div className="text-right align-self-center">
                            {selectedCard.card.brand === 'visa' ? (
                              <div>
                                <img src="assets/img/payments/visa.png" style={{ width: '50px' }} alt="" />
                              </div>
                            ) : ''}
                            {selectedCard.card.brand === 'mastercard' ? (
                              <div>
                                <img src="assets/img/payments/mastercard.svg" style={{ width: '50px' }} alt="" />
                              </div>
                            ) : ''}
                            {selectedCard.card.brand === 'amex' ? (
                              <div>
                                <img src="assets/img/payments/amex.svg" style={{ width: '50px' }} alt="" />
                              </div>
                            ) : ''}
                            {selectedCard.card.brand === 'cartes_bancaires' ? (
                              <div>
                                <img src="assets/img/payments/cartes_bancaires.svg" style={{ width: '50px' }} alt="" />
                              </div>
                            ) : ''}
                            {selectedCard.card.brand === 'diners' ? (
                              <div>
                                <img src="assets/img/payments/diners.svg" style={{ width: '50px' }} alt="" />
                              </div>
                            ) : ''}
                            {selectedCard.card.brand === 'discover' ? (
                              <div>
                                <img src="assets/img/payments/discover.png" style={{ width: '50px' }} alt="" />
                              </div>
                            ) : ''}
                            {selectedCard.card.brand === 'jcb' ? (
                              <div>
                                <img src="assets/img/payments/jcb.svg" style={{ width: '50px' }} alt="" />
                              </div>
                            ) : ''}
                            {selectedCard.card.brand === 'unionpay' ? (
                              <div>
                                <img src="assets/img/payments/uninpay.png" style={{ width: '50px' }} alt="" />
                              </div>
                            ) : ''}
                          </div>
                        </div>
                      </div>
                    </label>
                  ) : (
                    <>
                      {!showAllCards && i < 3 ? (
                        <label className="w-100" key={c.id}>
                          <div className="text-blue py-3 px-4" style={{ backgroundColor: '#efefef', borderRadius: '10px' }}>
                            <div className="d-flex align-items-center ml-2">
                              <div className="mr-4">
                                <input type="radio" id={c.id} name="card" onChange={() => setSelectedCard(c)} />
                              </div>
                              <div>
                                <h4 className="text-blue">{c.name}</h4>
                                <h6 className="text-blue font-weight-normal">XXXX-XXXX-XXXX-{c.card?.last4} {c.id === cards.defaultPaymentMethod && '(Principal)'}</h6>
                              </div>
                            </div>
                          </div>
                        </label>
                      ) : ''}
                      {showAllCards ? (
                        <label className="w-100" key={c.id}>
                          <div className="text-blue py-3 px-4" style={{ backgroundColor: '#efefef', borderRadius: '10px' }}>
                            <div className="d-flex align-items-center ml-2">
                              <div className="mr-4">
                                <input type="radio" id={c.id} name="card" onChange={() => setSelectedCard(c)} />
                              </div>
                              <div>
                                <h4 className="text-blue">{c.name}</h4>
                                <h6 className="text-blue font-weight-normal">XXXX-XXXX-XXXX-{c.card?.last4} {c.id === cards.defaultPaymentMethod && '(Principal)'}</h6>
                              </div>
                            </div>
                          </div>
                        </label>
                      ) : ''}
                    </>
                  )}
                </>
              )
            }) : ''}
          </form>
          <div className="d-flex justify-content-between align-items-center">
            {cards.paymentMethods && cards.paymentMethods.length > 3 ? (
              <button type="button" onClick={() => setShowAllCards(!showAllCards)} className="btn p-1 bg-transparent text-16 text-blue">
                {showAllCards ? 'Ver menos' : 'Ver todas'}
                <i className="small-icon icon-back p-0 m-0" style={{ transform: showAllCards ? 'rotate(90deg)' : 'rotate(270deg)', width: '50px', height: '12px' }} />
              </button>
            ) : ''}
            <Link to="/payments/add-card" className="btn p-1 bg-transparent text-16 text-blue">
              <u>Agregar Tarjeta</u>
            </Link>
          </div>
        </div>
        {/* TRANSFERENCIA SPEI */}
        <div className="card-incidences p-3 mt-4">
          <div className="d-flex justify-content-between">
            <h4 className="text-blue">Transferencia SPEI</h4>
            <div className="card-incidences mb-auto py-1 px-2">
              <img src="./assets/img/payments/spei.png" width="35" alt="spei" />
            </div>
          </div>
          <button
            type="button"
            onClick={() => setMethod('spei')}
            className="btn mx-auto bg-blue tex mt-3 w-100 d-flex align-items-center justify-content-center"
          >
            Hacer Transferencia
          </button>
        </div>
        {/* PAGO EN TIENDAS */}
        <div className="card-incidences p-3 mt-4 mb-5">
          <div className="text-center">
            <h4 className="text-blue">Tiendas donde pagar</h4>
          </div>
          <div className="d-flex align-items-center justify-content-around my-4">
            {/* PAGO EN WALMART */}
            {/* <button
              onClick={() => {
                setMethod('store');
                setStore('Walmart');
              }}
              className="btn card-incidences d-block my-4 w-80 h-100 px-1 py-4 mr-4"
            >
              <img src="./assets/img/payments/walmart.png" alt="walmart" className="w-90" />
            </button> */}
            <button
              onClick={() => setMethod('oxxo')}
              className="btn card-incidences w-80 h-100 px-1 py-2 my-4"
            >
              <img src="./assets/img/payments/oxxo.png" alt="oxxo" className="w-80" />
            </button>
          </div>
        </div>
        {selectedCard && (
          <div className="footer-pay d-flex justify-content-center text-center mt-4">
            <button
              onClick={() => setMethod('card')}
              className="btn btn-short bg-blue px-5"
            >
              Siguiente
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const SelectQuota = ({
  selectedCard,
  method,
  selectAllQuotas,
  onSelectQuotas,
  selectedQuotas,
  pendingTotal,
  quotas,
  payTotal,
  onSubmit,
  cards,
  today,
}) => {
  return (
    <div className="container">
      <h5 className="text-blue text-center">
        Metodo de pago
      </h5>
      {selectedCard && method === 'card' ? (
        <label htmlFor="card" className="w-100">
          <div className="text-white py-3 px-4 mb-3" style={{ backgroundColor: '#868686', borderRadius: '10px' }}>
            <div className="d-flex align-items-center ml-2 mb-4">
              <div className="mr-2">
                <input type="radio" checked={true} onChange={() => { }} name="radio" />
                <span className="checkmark" />
              </div>
              <div>
                <h4 className="text-white">{selectedCard.name}</h4>
                <h6 className="text-white font-weight-normal">XXXX-XXXX-XXXX-{selectedCard.card?.last4} {selectedCard.id === cards.defaultPaymentMethod && '(Principal)'}</h6>
              </div>
            </div>
            <div className="d-flex justify-content-between mx-2 mb-2">
              <div>
                <div className="text-12">
                  Vencimiento
                </div>
                <div className="text-16">
                  {selectedCard.card?.exp_month <= 9 ? `0${selectedCard.card?.exp_month}` : selectedCard.card?.exp_year}/{selectedCard.card?.exp_year?.toString()?.substring(2)}
                </div>
              </div>
              {selectedCard.metadata.name ? (
                <div>
                  <div className="text-12">
                    Nombre
                  </div>
                  <div className="text-16">
                    <span>{selectedCard.metadata.name}</span>
                  </div>
                </div>
              ) : ''}
              <div className="text-right align-self-center">
                {selectedCard.card.brand === 'visa' ? (
                  <div>
                    <img src="assets/img/payments/visa.png" style={{ width: '50px' }} alt="" />
                  </div>
                ) : ''}
                {selectedCard.card.brand === 'mastercard' ? (
                  <div>
                    <img src="assets/img/payments/mastercard.svg" style={{ width: '50px' }} alt="" />
                  </div>
                ) : ''}
                {selectedCard.card.brand === 'amex' ? (
                  <div>
                    <img src="assets/img/payments/amex.svg" style={{ width: '50px' }} alt="" />
                  </div>
                ) : ''}
                {selectedCard.card.brand === 'cartes_bancaires' ? (
                  <div>
                    <img src="assets/img/payments/cartes_bancaires.svg" style={{ width: '50px' }} alt="" />
                  </div>
                ) : ''}
                {selectedCard.card.brand === 'diners' ? (
                  <div>
                    <img src="assets/img/payments/diners.svg" style={{ width: '50px' }} alt="" />
                  </div>
                ) : ''}
                {selectedCard.card.brand === 'discover' ? (
                  <div>
                    <img src="assets/img/payments/discover.png" style={{ width: '50px' }} alt="" />
                  </div>
                ) : ''}
                {selectedCard.card.brand === 'jcb' ? (
                  <div>
                    <img src="assets/img/payments/jcb.svg" style={{ width: '50px' }} alt="" />
                  </div>
                ) : ''}
                {selectedCard.card.brand === 'unionpay' ? (
                  <div>
                    <img src="assets/img/payments/uninpay.png" style={{ width: '50px' }} alt="" />
                  </div>
                ) : ''}
              </div>
            </div>
          </div>
        </label>
      ) : ''}
      <h5 className="text-blue text-center mb-5">
        Selecciona el monto a pagar
      </h5>
      <div className="bg-beige p-3 pt-5 rounded-top w-100  mx-auto">
        {quotas ? quotas.map((q, i) => {
          const checked = selectedQuotas.find((quo) => quo === q.id)
          return (
            <div className="w-100" key={q.id}>
              <label htmlFor={`quota_${i}_${q.id}`} className="d-flex justify-content-between">
                <div className="mr-4 ml-2">
                  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="23" fillOpacity="0" stroke="#3927AB" strokeWidth="4" />
                    {checked && <circle cx="25" cy="25" r="16" fill="#3927AB" />}
                  </svg>
                </div>
                <input type="checkbox" className="d-none" onChange={() => onSelectQuotas(q)} id={`quota_${i}_${q.id}`} />
                <div className="w-100">
                  <h6 className="text-blue">{q.name}</h6>
                  <div>{moment(q.expirationDate).isBefore(new Date()) ? `Vencido hace ${moment(today).diff(q.expirationDate, 'days')} días` : ''}</div>
                </div>
                <h5 className="text-blue">
                  {formatter.format(Math.abs(q.amount))}.00
                </h5>
              </label>
            </div>
          )
        }) : ''}
        <div className="w-100">
          <label htmlFor="allQuotas" className="d-flex justify-content-between">
            <div className="mr-4 ml-2">
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="23" fillOpacity="0" stroke="#3927AB" strokeWidth="4" />
                {selectedQuotas?.length === quotas?.length && <circle cx="25" cy="25" r="16" fill="#3927AB" />}
              </svg>
            </div>
            <input type="checkbox" className="d-none" onClick={() => selectAllQuotas()} id="allQuotas" />
            <div className="w-100 align-items-center d-flex">
              <h6 className="text-red">Adeudo Total</h6>
            </div>
            <h5 className="text-red align-items-center d-flex">
              {formatter.format(pendingTotal)}.00
            </h5>
          </label>
        </div>
      </div>
      <div className="bg-blue rounded-bottom p-4 d-flex justify-content-between align-items-center">
        <h3 className="text-white">
          Total a pagar
        </h3>
        <h3 className="text-white">
          {payTotal}.00
        </h3>
      </div>
      <div className="p-5 mb-5">
        <p className="font-weight-bold">
          Recuerda que debes pagar tus cuotas antes del día de vencimiento, de lo contrario pueden generar cargos moratorios.
        </p>
        <p className="font-weight-bold">
          La autorización del cargo es responsabilidad del banco emisor, en caso de rechazo consulte a su banco.
        </p>
        <p className="font-weight-bold">
          No es posible realizar pagos si tu saldo es igual a cero a favor.
        </p>
      </div>
      {selectedQuotas.length ? (
        <div className="footer-pay d-flex justify-content-center text-center mt-5">
          <button
            onClick={() => onSubmit()}
            className="btn btn-pay bg-green d-flex align-items-center justify-content-center px-5"
          >
            <img src="./assets/img/payments/PAgar.svg" alt="pagar" width="60" className="" />
            <span className="mr-3">Pagar</span>
          </button>
        </div>
      ) : ''}
    </div>
  )
}

const Spei = ({ spei, pendingTotal, expiredTotal }) => {
  const copy = async () => {
    await navigator.clipboard.writeText(spei?.financial_addresses[0].spei.clabe);
    Alert.success({ title: 'Texto copiado!' })
  };
  return (
    <div className="container">
      <h5 className="text-blue text-center ">
        Pago con SPEI
      </h5>
      <div className="d-flex justify-content-center py-4">
        <div className="d-flex w-100 justify-content-center align-items-center card-incidences bg-beige mr-3">
          <div className="text-center">
            <h3 className="text-red mb-0">{formatter.format(pendingTotal)}</h3>
            <h6 className="text-red font-weight-normal">
              Adeudo Actual
            </h6>
          </div>
        </div>
        <div className="d-flex w-100 justify-content-center py-4 card-incidences bg-red">
          <div className="text-center">
            <h3 className="text-white mb-0">{formatter.format(expiredTotal)}</h3>
            <h6 className="text-white font-weight-normal">
              Vencido
            </h6>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between px-2">
        <h4 className="text-blue">Metodo de pago</h4>
        <div className="card-incidences mb-auto py-1 px-2">
          <img src="./assets/img/payments/spei.png" width="35" alt="spei" />
        </div>
      </div>
      <div className="card-incidences  py-5 px-5 mt-4">
        <h4 className="text-blue">SPEI</h4>
        <p className="text-blue pb-0 pt-2">REFERENCIA</p>
        <h4 className="text-blue" >{spei?.reference}</h4>
        <p className="text-blue pb-0 pt-2">NOMBRE DEL BANCO</p>
        <h4 className="text-blue" >{spei?.financial_addresses[0].spei.bank_name}</h4>
        <p className="text-blue pb-0 pt-2">CLABE</p>
        <h4 className="text-blue" >{spei?.financial_addresses[0].spei.clabe}</h4>
      </div>
      <button
        type="button"
        id="copy"
        onClick={() => copy()}
        className="btn btn-short mx-auto bg-blue tex mt-5 font-weight-normal px-5 d-block"
      >
        Copiar CLABE
      </button>
      <div className="my-5 pb-5">
        <ol className="w-70 mx-auto">
          <li>
            Entra a tu banca en línea
          </li>
          <li>
            Selecciona la opción:
            <br />
            <span className="font-weight-bold">
              Transferencia a otros bancos.
            </span>
          </li>
          <li>
            Elige como banco receptor a:
            <br />
            Sistema de transferencias y pagos (STP).
          </li>
          <li>
            Captura los{' '}
            <span className="font-weight-bold">
              18 digitos de la CLABE
            </span>{' '}
            que te proporcionaremos.
          </li>
          <li>
            Si puedes, por favor incluye el <span className="font-weight-bold">numero de referencia</span> cuando envies tu transferencia.
          </li>
          <li>
            Captura monto a pagar.
          </li>
          <li>
            Confirma tu pago.
          </li>
        </ol>
        <p className="text-blue font-weight-bold w-60 mx-auto py-5">
          * Si pagas después de las 9 pm tu
          Pago será valido hasta el día siguiente.
        </p>
        <Link to="/payments" className="btn btn-new-incidence w-50 mx-auto bg-blue d-flex align-items-center justify-content-center px-5">
          Regresar
        </Link>
      </div>
    </div>
  );
};

// const Store = ({ store, generateCode }) => {
//   return (
//     <div className="container">
//       <h5 className="text-blue text-center ">
//         Pago en tienda
//       </h5>
//       <div className="d-flex justify-content-between px-2">
//         <h4 className="text-blue">Metodo de pago</h4>
//         <div className="card-incidences mb-auto py-1 px-2">
//           <img src="./assets/img/payments/spei.png" width="35" alt="spei" />
//         </div>
//       </div>
//       <div className="card-incidences  py-3 px-5 mt-4">
//         <h4 className="text-blue">{store}</h4>
//         <p className="text-blue">Pago en caja</p>
//       </div>
//       <h4 className="text-blue py-4">Selecciona el monto</h4>

//       <div className="bg-beige p-3 pt-5 rounded-top">
//         {/* {quotas.length && quotas.map((q) => {
//           return (
//             <label className="container-radio" key={q.id}>
//               <div className="d-flex justify-content-around">
//                 <div>
//                   <h6 className="text-blue ">{q.name}</h6>
//                   <div>Vencido hace 15 días</div>
//                 </div>
//                 <h5 className="text-blue">
//                   {formatter.format(Math.abs(q.amount))}.00
//                 </h5>
//               </div>
//               <input type="radio" checked={true} onChange={() => setSelectedQuota(q)} name="radio" />
//               <span className="checkmark" />
//             </label>
//           )
//         })} */}
//       </div>
//       <div className="bg-blue rounded-bottom p-4 d-flex justify-content-between align-items-center">
//         <h3 className="text-white">
//           Total a pagar
//         </h3>
//         <h3 className="text-white">
//           $3,140.00 { }
//         </h3>
//       </div>
//       <p className="mt-5 px-5">
//         Una vez seleccionado el monto a pagar y hagas clic en generar código de pago, te enviaremos un correo electrónico con el
//         código de barras con el monto de pago seleccionado, el cual debes presentar en cajas para realizar el pago correspondiente.
//         Cada código tiene una vigencia, revisa que este vigente antes de realizar el pago.
//       </p>
//       <div className="my-5 pb-5">

//         <button onClick={generateCode} className="btn btn-new-incidence w-50 mx-auto bg-blue d-flex align-items-center justify-content-center ">
//           Generar Código
//         </button>
//       </div>
//     </div>
//   );
// };

const OxxoCode = ({ oxxo, item, payTotal }) => {
  return (
    <div className="container">
      <div className="text-center mt-5">
        <img src="./assets/img/civity-logo-blue.svg" alt="civity" className="w-40 mb-5" />
        <p className="font-weight-bold">Folio de Orden:</p>
        <p>{item.id.substring(4)}</p>
        <p className="font-weight-bold">Fecha limite de pago:</p>
        <p>{moment.unix(oxxo.expires_after).format('DD/MM/YYYY')}</p>
        <p className="font-weight-bold">Importe a Pagar</p>
        <p className="mb-4">{formatter.format(payTotal)}.00</p>
        <p className="font-weight-bold">Recuerda que puedes <br />realizar tu pago en <br />la siguiente tienda</p>
        <div className="d-flex align-items-center justify-content-around my-4">
          <div className="card-incidences w-30 h-100 px-1 py-2 my-4">
            <img src="./assets/img/payments/oxxo.png" alt="oxxo" className="w-80" />
          </div>
        </div>
        <p className="font-weight-bold mb-4">
          Instrucciones para realizar<br /> tu pago:
        </p>
        <p>
          Presenta el siguiente código de <br /> barras para realizar el pago:
        </p>
        <p className="my-5 d-flex justify-content-center">
          <Barcode value={oxxo.number} />
        </p>
        <p className="font-weight-bold">
          ¿Tienes algún problema con esta <br />transacción?
        </p>
        <p className="text-blue pb-5">
          <u>Ponte en contacto con nosotros para <br /> cualquier aclaración</u>
        </p>
      </div>
    </div>
  )
}

const PayFail = ({ selectedCard, paymentIntent }) => {
  return (
    <div className="text-center pt-5 mx-3">
      <img src="./assets/img/civity-logo-blue.svg" alt="civity" className="w-40 mb-5" />
      <h1 className="text-red" style={{ fontSize: '35px' }}>
        Tu pago no ha <br /> sido procesado
      </h1>
      <p className="mt-4 mx-4">
        Tu pago no se pudo realizar con exito...
        <br />
      </p>
      <div className="mt-4 font-weight-bold">
        <div className="d-flex justify-content-between mt-3">
          <span>Fecha de la transacción: </span>
          <span>{moment(new Date()).locale(false).format('DD/MM/YYYY')}</span>
        </div>
        {paymentIntent ? (
          <div className="d-flex justify-content-between mt-3">
            <span>Id de la transacción: </span>
            <span>{paymentIntent?.id}</span>
          </div>
        ) : ''}
        
        <div className="d-flex justify-content-between mt-3">
          <span>Tarjeta: </span>
          <span>X-{selectedCard?.card?.last4}</span>
        </div>
      </div>
      <Link
        to="/"
        className="btn btn-short mt-5 col-7 col-md-5 col-lg-3 mx-auto d-flex align-items-center justify-content-center text-center"
      >
        Terminar
      </Link>
    </div>
  )
};

const PaySuccess = ({ selectedCard, resident, payTotal, paymentIntent }) => {
  return (
    <div className="text-center pt-5">
      <img src="./assets/img/payments/Pago_exitoso.svg" alt="success" className="w-30" />
      <h1 className="text-blue" style={{ fontSize: '45px' }}>
        ¡Gracias <br />por tu pago!
      </h1>
      <p className="mt-4">
        El pago en linea ha sido aplicado
        <br />
        éxitosamente a tu tarjeta.
      </p>
      <div className="btn-short d-flex align-items-center justify-content-between px-4 mt-5 w-75 mx-auto">
        <span className="font-weight-normal">Pago realizado</span>
        <span>{formatter.format(Math.abs(payTotal))}.00</span>
      </div>
      <div className="mt-4 font-weight-bold">
        <div>
          <span>Fecha de pago: </span>
          {moment(new Date()).locale(false).format('DD MMMM YYYY')}
        </div>
        <div>
          <span>Tarjeta: </span>
          XXXX-XXXX-XXXX-{selectedCard?.card?.last4}
        </div>
        <div>
          <span>Cliente: </span>
          {resident.name} {resident.lastname}
        </div>
        <div>
          <span>No. de transacción: </span>
          {paymentIntent?.id}
        </div>
      </div>
      <Link
        to="/"
        className="btn btn-short mt-5 col-7 col-md-5 col-lg-3 mx-auto d-flex align-items-center justify-content-center text-center"
      >
        Terminar
      </Link>
    </div>
  );
}

export default ElectronicPay;

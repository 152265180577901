import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { servicePath } from "../../constants/defaultsValues";
import { formatter } from "../../helpers/Utils";
import Section from "../layouts/Section";
import { selectChargesStart } from "../../redux/auth/actions";
import { connect } from "react-redux";

const apiUrl = `${servicePath}/quotas`;
const apiGB = `${servicePath}/transactions`;
const apiOptions = `${servicePath}/condominiums/verified`;
function Payments() {
  const address = useSelector(state => state.address);
  const condominium = useSelector(state => state.condominium);
  const token = useSelector((state) => state.jwt);
  const [isLoaded, setIsLoaded] = useState(false);
  const [quotas, setQuotas] = useState([]);
  const [GB, setGB] = useState([]);
  const [selectedQuotas, setSelectedQuotas] = useState([]);
  const [pendingTotal, setPendingTotal] = useState(0);
  const [expiredTotal, setExpiredTotal] = useState(0);
  const [payoutTotal, setPayoutTotal] = useState(0);
  const [chargesTotal, setChargesTotal] = useState(0);
  const [unverified, setUnverified] = useState(0);
  const [options, setOptions] = useState(false);

  async function fetchData() {
    setIsLoaded(false);
    axios
      .get(
        `${apiUrl}?address=${address}&isActive=true&canceled=false&isCancellation=false&pending_gt=0&_limit=-1&_sort=expirationDate:DESC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setQuotas(res.data);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(true);
      });
    axios
      .get(
        `${apiGB}?address=${address}&canceled=false&_limit=-1&_sort=id:DESC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setGB(res.data);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(true);
      });
    axios.
      get(
        `${apiOptions}/${condominium}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOptions(res.data);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(true);
      });
  }

  useEffect(() => {
    let chargesCount = 0;
    let payoutCount = 0;
    let pendingCount = 0;
    let expiredCount = 0;
    let toVerifiedCount = 0;
    quotas.forEach((row) => {
      chargesCount += row.amount;
      if (row.pending) {
        if (moment(new Date()).isAfter(row.expirationDate)) {
          expiredCount += row.pending;
        } else {
          pendingCount += row.pending;
        }
      }
    });
    GB.forEach((row) => {
      if (!row.verified) {
        toVerifiedCount += row.amount;
      } else {
        payoutCount += row.amount;
      }
    })
    setPendingTotal(Math.abs(pendingCount));
    setExpiredTotal(Math.abs(expiredCount));
    setPayoutTotal(Math.abs(payoutCount));
    setChargesTotal(Math.abs(chargesCount));
    setUnverified(Math.abs(toVerifiedCount));
  }, [quotas, GB]);

  useEffect(() => {
    if (address) {
      fetchData();
    }
  }, [address]);

  return (
    <Section title={"Pagos"}>
      {isLoaded ? (
        <div>
          <div className="container py-5 mb-5">
            <div className="d-flex justify-content-center mb-3">
              <div className="d-flex w-100 justify-content-center align-items-center card-incidences py-4 bg-beige mr-3">
                <div className="text-center">
                  <h3 className="text-blue mb-0">{formatter.format(chargesTotal)}</h3>
                  <h6 className="text-blue font-weight-normal">
                    Cargos Totales
                  </h6>
                </div>
              </div>
              <Link to="/payments/history" className="d-flex w-100 justify-content-center align-items-center card-incidences py-4 bg-green">
                <div className="text-center">
                  <h3 className="text-blue mb-0">{formatter.format(payoutTotal)}</h3>
                  <h6 className="text-blue font-weight-normal">
                    Abonos Totales
                  </h6>
                </div>
              </Link>
            </div>
            <div className="d-flex justify-content-center mb-3">
              <div className="d-flex w-100 justify-content-center align-items-center card-incidences py-4 bg-beige mr-3">
                <div className="text-center">
                  <h3 className="text-red mb-0">{formatter.format(pendingTotal)}</h3>
                  <h6 className="text-red font-weight-normal">
                    Adeudo Actual
                  </h6>
                </div>
              </div>
              <div className="d-flex w-100 justify-content-center align-items-center card-incidences py-4 bg-red">
                <div className="text-center">
                  <h3 className="text-white mb-0">{formatter.format(expiredTotal)}</h3>
                  <h6 className="text-white font-weight-normal">
                    Vencido
                  </h6>
                </div>
              </div>
            </div>
            {options?.paymentInstructions || (unverified > 0) ? (
              <Link to="/payments/unverified" className="d-flex w-100 justify-content-center align-items-center card-incidences py-4" style={{ background: '#FFD65A' }}>
                <div className="text-center">
                  <h3 className="text-blue mb-0">{formatter.format(unverified)}</h3>
                  <h6 className="text-blue font-weight-normal">
                    Por verificar
                  </h6>
                </div>
              </Link>
            ) : ''}
            {/* FILTROS */}
            {/* <div className="d-flex justify-content-center my-5">
              <div className="col-5 div-select bg-beige mr-3 p-2">
                <select className="form-select w-100 bg-beige border-red" defaultValue="">
                  <option className="">
                    Todo
                  </option>
                </select>
              </div>
              <div className="col-5 div-select bg-beige p-2">
                <select className="form-select w-100 bg-beige" defaultValue="">
                  <option>
                    Todo
                  </option>
                </select>
              </div>
            </div> */}
            <div className="container container-news mt-5">
            </div>
            <div className="transaction-area pt-3 section-incidences mb-5">
              <form className="transaction-inner row align-items-stretch">
                {quotas.length ? quotas.map((quota, i) => (
                  <>
                    {/* <div className="col-12">
                      <div className="text-center">
                        <h3 className="title">Enero 2021</h3>
                      </div>
                    </div> */}
                    <QuotaCard
                      key={i}
                      quota={quota}
                      setSelectedQuotas={setSelectedQuotas}
                      selectedQuotas={selectedQuotas}
                    />
                  </>
                )) : (
                  <div className="text-center text-blue w-100 mx-auto mt-5">
                    <h3 className="title text-blue">
                      ¡Felicidades!
                    </h3>
                    Vas al corriente de tus pagos
                  </div>
                )}
              </form>
            </div>
          </div>
          {quotas.length && (options.electronicPayments || options.paymentInstructions) && (
            <div className="footer-pay d-flex justify-content-center text-center mt-4">
              <Link
                to={
                  options.electronicPayments &&
                    options.paymentInstructions ?
                    "/payments/options" :
                    options.paymentInstructions ?
                      "/payments/register" :
                      options.electronicPayments &&
                      "/payments/pay"
                }
                className="btn btn-pay bg-green d-flex align-items-center justify-content-center px-5"
              >
                <img src="./assets/img/payments/PAgar.svg" alt="pagar" width="60" className="" />
                <span className="mr-3">
                  {
                    options.electronicPayments &&
                      options.paymentInstructions ?
                      "Opciones" :
                      options.paymentInstructions ?
                        "Registra tu pago" :
                        options.electronicPayments &&
                        "Pagar"
                  }
                </span>
                <span className="mr-1">{selectedQuotas.length > 1 && `(${selectedQuotas.length})`}</span>
              </Link>
            </div>
          )}

        </div>
      ) : (
        ""
      )}
    </Section>
  )
}

const QuotaCard = ({ quota }) => {
  return (
    <>
      <div className="card-area w-100 mb-2" style={{ backgroundColor: '#f8f7f7' }}>
        <div className="col-12 ">
          <div className="details">
            <h5 className="w-100">{quota.name}</h5>
          </div>
          <div className="mt-3 mb-0 pb-0 d-flex justify-content-between">
            <div>
              <h6 className="text-blue font-weight-normal">{moment(quota.ExpirationDate).format('DD/MM/YYYY')}</h6>
            </div>
            <div>
              <h6 className="text-red font-weight-normal">Cargo</h6>
            </div>
            <div>
              <h5 className="text-red font-weight-bold">{formatter.format(Math.abs(quota.pending))}.00</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCharges: state.selectedCharges,
  };
};

export default connect(mapStateToProps, { selectChargeAction: selectChargesStart })(
  Payments
);

import React from 'react';

const Arrow = () => {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.999999 16L9 8.5L1 1" stroke="white" />
    </svg>
  );
};
export default Arrow;

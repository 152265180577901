import Section from "../layouts/Section";
import { Link } from "react-router-dom";
import axios from "axios";
import { servicePath } from "../../constants/defaultsValues";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const apiCards = `${servicePath}/payment-methods`;
function PaymentMethods() {
  const resident = useSelector((state) => state.resident);
  const user = useSelector((state) => state.user);
  const address = useSelector(state => state.address);
  const token = useSelector((state) => state.jwt);
  const [cards, setCards] = useState({});
  // const [isLoaded, setIsLoaded] = useState(true);
  // const [selectedCard, setSelectedCard] = useState(null);

  async function fetchData() {
    axios.get(
      `${apiCards}/stripe/${user.id}/${resident.addresses.find((a) => a.id === address).condominium}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        setCards(res.data);
        setSelectedCard(res.data.paymentMethods.find((c) => c.id === res.data.defaultPaymentMethod));
        // setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  const deleteCard = (card) => {
    axios.post(`${apiCards}/delete`, {
      card,
      condominium: resident.addresses.find((a) => a.id === address).condominium,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      })
  }

  return (
    <Section allNotification={false} searchPopup={true} withBack>
      <div className="cart-area pd-top-36 mg-bottom-40">
        <div className="container">
          <div className="section-title d-flex align-items-center justify-content-between">
            <div className="text-cen ">
              <h2 className="titl text-blue my-0">Tarjetas</h2>
            </div>
            <div className="count-title">{cards.paymentMethods?.length}</div>
          </div>
          {cards.paymentMethods?.length ? cards.paymentMethods.map((c, i) => (
            <div key={i} className="ba-cart-inner mb-3" style={{
              backgroundImage: `url(assets/img/bg/${i === 0 ? 4 : i > 0 && 9 + i
                }.png)`
            }}>
              {/* {c.metadata.name ? ( */}
              <div className="d-flex justify-content-between">
                <div>
                  <p>
                    Nombre
                  </p>
                  <div className="text-16">
                    <h5>{c?.metadata?.name || ' '}</h5>
                  </div>
                  {/* ) : ''} */}
                  <p>Número</p>
                  <h5>0000 0000 0000 {c.card.last4}</h5>
                  <div>
                    <p>Expiración</p>
                    <h5>{c.card?.exp_month <= 9 ? `0${c.card?.exp_month}` : c.card?.exp_year}/{c.card?.exp_year?.toString()?.substring(2)}</h5>
                  </div>
                </div>
                <div className="mr-2 d-flex flex-column">
                  <button type="button" className=" mx-0 py-2 border-0 bg-transparent text-red rounded" data-toggle="modal" data-target={`#modal-delete-${i}`}>
                    <i className="xsmall-icon icon-delete pl-0" ></i>
                  </button>
                  {/* MODAL DELETE */}
                  <div id={`modal-delete-${i}`} className="modal fade">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body text-center mt-4 mb-1">
                          <h4 className="text-blue font-weight-bold">¿Seguro que quieres eliminar esta tarjeta?</h4>
                        </div>
                        <h5 className="text-center mb-4">
                          {c?.metadata?.name ? `${c?.metadata?.name} -` : ''} {c.card.last4}
                        </h5>
                        <div className="modal-footer justify-content-between mx-3">
                          <button type="button" className="btn btn-shadow  my-2" data-dismiss="modal">Cancelar</button>
                          <button onClick={() => deleteCard(c)} type="button" className="btn btn-red my-2" data-dismiss="modal">Confirmar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {c.card.brand === 'visa' ? (
                    <div className="mt-auto">
                      <img src="assets/img/payments/visa.png" style={{ width: '50px' }} alt="" />
                    </div>
                  ) : ''}
                  {c.card.brand === 'mastercard' ? (
                    <div className="mt-auto">
                      <img src="assets/img/payments/mastercard.svg" style={{ width: '50px' }} alt="" />
                    </div>
                  ) : ''}
                  {c.card.brand === 'amex' ? (
                    <div className="mt-auto">
                      <img src="assets/img/payments/amex.svg" style={{ width: '50px' }} alt="" />
                    </div>
                  ) : ''}
                  {c.card.brand === 'cartes_bancaires' ? (
                    <div className="mt-auto">
                      <img src="assets/img/payments/cartes_bancaires.svg" style={{ width: '50px' }} alt="" />
                    </div>
                  ) : ''}
                  {c.card.brand === 'diners' ? (
                    <div className="mt-auto">
                      <img src="assets/img/payments/diners.svg" style={{ width: '50px' }} alt="" />
                    </div>
                  ) : ''}
                  {c.card.brand === 'discover' ? (
                    <div className="mt-auto">
                      <img src="assets/img/payments/discover.png" style={{ width: '50px' }} alt="" />
                    </div>
                  ) : ''}
                  {c.card.brand === 'jcb' ? (
                    <div className="mt-auto">
                      <img src="assets/img/payments/jcb.svg" style={{ width: '50px' }} alt="" />
                    </div>
                  ) : ''}
                  {c.card.brand === 'unionpay' ? (
                    <div className="mt-auto">
                      <img src="assets/img/payments/uninpay.png" style={{ width: '50px' }} alt="" />
                    </div>
                  ) : ''}
                </div>
              </div>
            </div>
          )) : ''}
          <div className="container text-center my-4">
            <Link
              to="/payments/add-card"
              className="btn btn-new-incidence col-8 col-md-6 col-lg-4 mx-auto d-flex align-items-center justify-content-center text-center"
            >
              Agregar
            </Link>
          </div>
          {/* <div className="ba-cart-inner mb-3" style={{ backgroundImage: "url(assets/img/bg/10.png)" }}>
            <p>Balance</p>
            <h4>$56,985.00</h4>
            <p>Card Number</p>
            <h5>0000 0000 0000 0909</h5>
            <div className="row">
              <div className="col-4">
                <p>Expiry</p>
                <h5>12/10</h5>
              </div>
              <div className="col-8">
                <p>CCV</p>
                <h5>513</h5>
              </div>
            </div>
            <p>aron smith</p>
          </div> */}

          {/* <div className="ba-cart-inner mb-3" style={{ backgroundImage: "url(assets/img/bg/11.png)" }}>
            <p>Balance</p>
            <h4>$56,985.00</h4>
            <p>Card Number</p>
            <h5>0000 0000 0000 0909</h5>
            <div className="row">
              <div className="col-4">
                <p>Expiry</p>
                <h5>12/10</h5>
              </div>
              <div className="col-8">
                <p>CCV</p>
                <h5>513</h5>
              </div>
            </div>
            <p>aron smith</p>
          </div> */}

          {/* <div className="ba-cart-inner mb-3" style={{ backgroundImage: "url(assets/img/bg/12.png)" }}>
            <p>Balance</p>
            <h4>$56,985.00</h4>
            <p>Card Number</p>
            <h5>0000 0000 0000 0909</h5>
            <div className="row">
              <div className="col-4">
                <p>Expiry</p>
                <h5>12/10</h5>
              </div>
              <div className="col-8">
                <p>CCV</p>
                <h5>513</h5>
              </div>
            </div>
            <p>aron smith</p>
          </div> */}

          {/* <div className="ba-cart-inner" style={{ backgroundImage: "url(assets/img/bg/13.png)" }}>
            <p>Balance</p>
            <h4>$56,985.00</h4>
            <p>Card Number</p>
            <h5>0000 0000 0000 0909</h5>
            <div className="row">
              <div className="col-4">
                <p>Expiry</p>
                <h5>12/10</h5>
              </div>
              <div className="col-8">
                <p>CCV</p>
                <h5>513</h5>
              </div>
            </div>
            <p>aron smith</p>
          </div> */}
        </div>
      </div>
    </Section>
  );
}

export default PaymentMethods;

import React from "react";
import { Router, Route, Switch, HashRouter } from "react-router-dom";
import history from "./History";

/* Componentes Usuario */
import Login from "./components/user/login";
import ResetPassword from "./components/user/resetPassword";
import ResetEmail from "./components/user/resetEmail";
import Logout from "./components/user/logout";
import Register from "./components/user/register";
import Welcome from "./components/user/welcome";

/* componentes PAGOS */
import Payments from './components/payments';
import Payment from './components/payments/payment';
import PaymentSuccess from './components/payments/withCard/paymentSuccess';
import PaymentFail from './components/payments/withCard/paymentFail';
import ElectronicPay from "./components/payments/electronicPay";
import AddPaymentCard from "./components/payments/withCard/addPaymentCard";
import PaymentMethods from "./components/payments/paymentMethods";
import PaymentsHistory from "./components/payments/history";
import PaymentsUnverified from "./components/payments/unverified";
import PayOptions from "./components/payments/payOptions";
import RegisterPayment from "./components/payments/registerPayment";
import HowToPay from "./components/payments/howToPay";

/* componentes TRANSPARENCIA */
import Transparency from "./components/transparency";

/* componentes NOTICIAS */
import News from "./components/news/index";
import New from "./components/news/new";

/* Componentes INCIDENCIAS */
import Incidences from "./components/incidences/List";
import CreateIncidences from "./components/incidences/create";
import ResumenIncidences from "./components/incidences/Resumen";
import MonitoringIncidences from "./components/incidences/Monitoring";

/* Componentes RESERVACIONES */
import Reservations from "./components/reservations/";
import Reservation from "./components/reservations/Reservation";
import CreateReservations from "./components/reservations/create";
import ResumenReservations from "./components/reservations/Resumen";
import MonitoringReservations from "./components/reservations/Monitoring";
import Home from "./components/app/Home";
import About from "./components/About";
// import AllPages from "./components/AllPages";
import BillPay from "./components/BillPay";
import Blog from "./components/Blog";
import BlogDetails from "./components/BlogDetails";
import Carts from "./components/Carts";
import Components from "./components/Components";
import ComponentsAccordion from "./components/ComponentsAccordion";
import ComponentsAlerts from "./components/ComponentsAlerts";
import ComponentsButtons from "./components/ComponentsButtons";
import ComponentsModals from "./components/ComponentsModals";
import ComponentsNotifications from "./components/ComponentsNotifications";
import ComponentsProgress from "./components/ComponentsProgress";
// import ComponentsTabs from "./components/ComponentsTabs";
import ComponentsTooltip from "./components/ComponentsTooltip";
import Contact from "./components/Contact";
// import Notification from "./components/Notification";
import Notifications from "./components/notifications";
import Saving from "./components/Saving";
import Transaction from "./components/Transaction";
import TransactionDetails from "./components/TransactionDetails";
import UserSetting from "./components/account/UserSetting";
// import UserVerification from "./components/UserVerification";
import AuthRoute from "./components/hooks/AuthRoute";
import { connect } from "react-redux";

const Routes = () => {
  // const jsonWebToken = jwt.jwt;
  // console.log(jwt);
  return (
    <Router history={history}>
      <HashRouter basename="/">
        {/* <Redirect from="/#/reset-password" to="/#/reset-password" /> */}
        <Switch>
          {/* RUTAS DE USUARIO */}
          <Route
            exact
            path={"/login"}
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            type="private"
            path={"/reset-password/:token"}
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            type="private"
            path={"/reset-email/:token"}
            render={(props) => <ResetEmail {...props} />}
          />
          <Route
            exact
            path={"/register"}
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path={"/welcome"}
            render={(props) => <Welcome {...props} />}
          />
          {/* FIN DE RUTAS DE USUARIO   RUTAS APP */}
          <AuthRoute exact path={"/"} render={(props) => <Home {...props} />} />
          <AuthRoute
            exact
            path={"/payments"}
            render={(props) => <Payments {...props} />}
          />
          <AuthRoute
            exact
            path={"/payment/:id"}
            render={(props) => <Payment {...props} />}
          />
          <AuthRoute
            exact
            path={"/payment-success/:id"}
            render={(props) => <PaymentSuccess {...props} />}
          />
          <AuthRoute
            exact
            path={"/payment-fail/:id"}
            render={(props) => <PaymentFail {...props} />}
          />
          <AuthRoute
            exact
            path={"/payments/pay"}
            render={(props) => <ElectronicPay {...props} />}
          />
          <AuthRoute
            exact
            path={"/payments/add-card"}
            render={(props) => <AddPaymentCard {...props} />}
          />
          <AuthRoute
            exact
            path={"/payments/payment-methods"}
            render={(props) => <PaymentMethods {...props} />}
          />
          <AuthRoute
            exact
            path={"/payments/history"}
            render={(props) => <PaymentsHistory {...props} />}
          />
          <AuthRoute
            exact
            path={"/payments/unverified"}
            render={(props) => <PaymentsUnverified {...props} />}
          />
          <AuthRoute
            exact
            path={"/payments/options"}
            render={(props) => <PayOptions {...props} />}
          />
          <AuthRoute
            exact
            path={"/payments/register"}
            render={(props) => <RegisterPayment {...props} />}
          />
          <AuthRoute
            exact
            path={"/payments/how-to-pay"}
            render={(props) => <HowToPay {...props} />}
          />
          <AuthRoute
            exact
            path="/transparency"
            render={(props) => <Transparency {...props} />}
          />
          <AuthRoute
            exact
            path={"/news"}
            render={(props) => <News {...props} />}
          />
          <AuthRoute
            exact
            path={"/new/:id"}
            render={(props) => <New {...props} />}
          />
          <AuthRoute
            exact
            path={"/incidences"}
            render={(props) => <Incidences {...props} />}
          />
          <AuthRoute
            exact
            path={"/incidences/create"}
            render={(props) => <CreateIncidences {...props} />}
          />
          <AuthRoute
            exact
            path={"/incidences/resumen/:id"}
            render={(props) => <ResumenIncidences {...props} />}
          />
          <AuthRoute
            exact
            path={"/incidences/monitoring/:id"}
            render={(props) => <MonitoringIncidences {...props} />}
          />
          <AuthRoute
            exact
            path={"/reservations"}
            render={(props) => <Reservations {...props} />}
          />
          <AuthRoute
            exact
            path={"/reservation/:id"}
            render={(props) => <Reservation {...props} />}
          />
          <AuthRoute
            exact
            path={"/reservations/create"}
            render={(props) => <CreateReservations {...props} />}
          />
          <AuthRoute
            exact
            path={"/reservations/resumen/:id"}
            render={(props) => <ResumenReservations {...props} />}
          />
          <AuthRoute
            exact
            path={"/reservations/monitoring/:id"}
            render={(props) => <MonitoringReservations {...props} />}
          />
          {/* FIN DE RUTAS APP */}
          <AuthRoute
            exact
            path={"/about"}
            render={(props) => <About {...props} />}
          />
          <AuthRoute
            exact
            path={"/logout"}
            render={(props) => <Logout {...props} />}
          />
          <AuthRoute
            exact
            path={"/bill-pay"}
            render={(props) => <BillPay {...props} />}
          />
          <AuthRoute
            exact
            path={"/all-pages"}
            render={(props) => <BillPay {...props} />}
          />
          <AuthRoute
            exact
            path={"/blog"}
            render={(props) => <Blog {...props} />}
          />
          <AuthRoute
            exact
            path={"/blog-details"}
            render={(props) => <BlogDetails {...props} />}
          />
          <AuthRoute
            exact
            path={"/carts"}
            render={(props) => <Carts {...props} />}
          />
          <AuthRoute
            exact
            path={"/components"}
            render={(props) => <Components {...props} />}
          />
          <AuthRoute
            exact
            path={"/components-accordion"}
            render={(props) => <ComponentsAccordion {...props} />}
          />
          <AuthRoute
            exact
            path={"/components-alerts"}
            render={(props) => <ComponentsAlerts {...props} />}
          />
          <AuthRoute
            exact
            path={"/components-buttons"}
            render={(props) => <ComponentsButtons {...props} />}
          />
          <AuthRoute
            exact
            path={"/components-modal"}
            render={(props) => <ComponentsModals {...props} />}
          />
          <AuthRoute
            exact
            path={"/components-notifications"}
            render={(props) => <ComponentsNotifications {...props} />}
          />
          <AuthRoute
            exact
            path={"/components-progress"}
            render={(props) => <ComponentsProgress {...props} />}
          />
          <AuthRoute
            exact
            path={"/components-tooltip"}
            render={(props) => <ComponentsTooltip {...props} />}
          />
          {/*<Route exact path={'/components-tooltip'} render={(props) => (<ComponentsTooltip {...props} />)} />*/}
          <AuthRoute
            exact
            path={"/contact"}
            render={(props) => <Contact {...props} />}
          />
          {/* <AuthRoute
            exact
            path={"/notification"}
            render={(props) => <Notification {...props} />}
          /> */}
          <AuthRoute
            exact
            path={"/notifications"}
            render={(props) => <Notifications {...props} />}
          />
          <AuthRoute
            exact
            path={"/saving"}
            render={(props) => <Saving {...props} />}
          />
          <AuthRoute
            exact
            path={"/transaction"}
            render={(props) => <Transaction {...props} />}
          />
          <AuthRoute
            exact
            path={"/transaction-details"}
            render={(props) => <TransactionDetails {...props} />}
          />
          <AuthRoute
            exact
            path={"/user-setting"}
            render={(props) => <UserSetting {...props} />}
          />
          {/* <AuthRoute
            exact
            path={"/user-verification"}
            render={(props) => <UserVerification {...props} />}
          /> */}
        </Switch>
      </HashRouter>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    jwt: state.jwt,
  };
};

export default connect(mapStateToProps)(Routes);

import Section from "../layouts/Section";
// import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

// const goBack = () => {
//   useHistory.goBack();
// };
/* const errors = {
  password: null,
  username: null,
}; */
const Logout = (jwt) => {
  const dispatch = useDispatch();

  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={"Logout"}
      disabledHeader={true}
      disabledFooter={true}
    >
      <div className="w-100 text-center">
        {/* <button className="btn btn-purple mx-auto" onClick={() => dispatch(loginUserStart({username,password}))}>Login</button> */}
        <button
          className="btn btn-login mx-auto"
          onClick={(e) => {
            // e.preventDefault();
            dispatch({
              type: "LOGOUT_USER",
              payload: {
                user: null,
                jwt: null,
              },
            });
          }}
        >
          Iniciar sesión
        </button>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    jwt: null,
    user: null,
  };
};

export default connect(mapStateToProps)(Logout);

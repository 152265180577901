import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../layouts/Section';
import ElectronicPaymentsIcon from '../svg/ElectronicPaymentsIcon';
import RegisterPaymentIcon from '../svg/RegisterPaymentIcon';

function PayOptions() {
  const condominiumId = useSelector(state => state.condominium);
  const token = useSelector(state => state.jwt);
  const [condominium, setCondominium] = useState(true);
  const [isLoaded, setIsLoaded] = useState(true);

  async function fetchData() {
    axios
      .get(
        `${servicePath}/condominiums/${condominiumId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCondominium(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Section
      title={"Pagar"}
      withBack={true}
      noBorder={true}
      cancelTo="/payments"
    >
      {isLoaded ? (
        <div className="transaction-area pt-5 mx-auto text-center">
          <div>
            <h5 className="text-blue">Elige una opción</h5>
            <Link
              to="/payments/how-to-pay"
              className="btn bg-blue font-weight-bold"
            >
              ¿Cómo pagar?
            </Link>
            <div id="modal-how-pay" className="modal fade">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0">
                    <h4 className="modal-title text-center mx-auto text-blue w-100 pl-5 pt-2">Pago electrónico</h4>
                    <button type="button" className="close" data-dismiss="modal">
                      <span aria-hidden="true">×</span>
                      <span className="sr-only">cerrar</span>
                    </button>
                  </div>
                  <div className="modal-body text-justify mx-3" style={{ fontSize: '15px' }}>
                    <p>
                      Paga tus adeudos de manera rápida y segura, a través del pago electrónico.
                    </p>
                    <p>
                      Al hacer el pago no es necesario que envíes un comprobante ya que este se genera y envía automáticamente.
                    </p>
                  </div>
                  <div className="modal-footer text-center border-0 mb-4">
                    <button type="button" className="btn bg-blue mx-auto" data-dismiss="modal">Aceptar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mt-5 mx-3">
            {condominium?.electronicPayments ? (
              <div className="card-beige bg-beige d-flex justify-content-between align-items-center px-4">
                <button
                  type="button"
                  className="bg-transparent border-0 p-0 mr-5"
                  data-toggle="modal"
                  data-target="#modal-electronic-payment">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="11" fill="#2C2DA3" />
                    <path d="M11.4609 13.7969H10.0078C10.013 13.2969 10.0573 12.888 10.1406 12.5703C10.2292 12.2474 10.3724 11.9531 10.5703 11.6875C10.7682 11.4219 11.0312 11.1198 11.3594 10.7812C11.599 10.5365 11.8177 10.3073 12.0156 10.0938C12.2188 9.875 12.3828 9.64062 12.5078 9.39062C12.6328 9.13542 12.6953 8.83073 12.6953 8.47656C12.6953 8.11719 12.6302 7.80729 12.5 7.54688C12.375 7.28646 12.1875 7.08594 11.9375 6.94531C11.6927 6.80469 11.388 6.73438 11.0234 6.73438C10.7214 6.73438 10.4349 6.78906 10.1641 6.89844C9.89323 7.00781 9.67448 7.17708 9.50781 7.40625C9.34115 7.63021 9.25521 7.92448 9.25 8.28906H7.80469C7.8151 7.70052 7.96094 7.19531 8.24219 6.77344C8.52865 6.35156 8.91406 6.02865 9.39844 5.80469C9.88281 5.58073 10.4245 5.46875 11.0234 5.46875C11.6849 5.46875 12.2474 5.58854 12.7109 5.82812C13.1797 6.06771 13.5365 6.41146 13.7812 6.85938C14.026 7.30208 14.1484 7.82812 14.1484 8.4375C14.1484 8.90625 14.0521 9.33854 13.8594 9.73438C13.6719 10.125 13.4297 10.4922 13.1328 10.8359C12.8359 11.1797 12.5208 11.5078 12.1875 11.8203C11.901 12.0859 11.7083 12.3854 11.6094 12.7188C11.5104 13.0521 11.4609 13.4115 11.4609 13.7969ZM9.94531 16.2734C9.94531 16.0391 10.0182 15.8411 10.1641 15.6797C10.3099 15.5182 10.5208 15.4375 10.7969 15.4375C11.0781 15.4375 11.2917 15.5182 11.4375 15.6797C11.5833 15.8411 11.6562 16.0391 11.6562 16.2734C11.6562 16.4974 11.5833 16.6901 11.4375 16.8516C11.2917 17.013 11.0781 17.0938 10.7969 17.0938C10.5208 17.0938 10.3099 17.013 10.1641 16.8516C10.0182 16.6901 9.94531 16.4974 9.94531 16.2734Z" fill="white" />
                  </svg>
                </button>
                <Link to="/payments/pay" className="d-flex align-items-center justify-content-between w-100">
                <h5 className="text-blue mb-0">
                  Pago electrónico
                </h5>
                <ElectronicPaymentsIcon />
                <div id="modal-electronic-payment" className="modal fade">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <h4 className="modal-title text-center mx-auto text-blue w-100 pl-5 pt-2">Pago electrónico</h4>
                        <button type="button" className="close" data-dismiss="modal">
                          <span aria-hidden="true">×</span>
                          <span className="sr-only">cerrar</span>
                        </button>
                      </div>
                      <div className="modal-body text-justify mx-3" style={{ fontSize: '15px' }}>
                        <p>
                          Paga tus adeudos de manera rápida y segura, a través del pago electrónico.
                        </p>
                        <p>
                          Al hacer el pago no es necesario que envíes un comprobante ya que este se genera y envía automáticamente.
                        </p>
                      </div>
                      <div className="modal-footer text-center border-0 mb-4">
                        <button type="button" className="btn bg-blue mx-auto" data-dismiss="modal">Aceptar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              </div>
            ) : ''}
            {condominium?.paymentInstructions ? (
              <div className="card-beige bg-beige d-flex justify-content-between align-items-center px-4">
                <button
                  type="button"
                  className="bg-transparent border-0 p-0 mr-5"
                  data-toggle="modal"
                  data-target="#modal-register-payment">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="11" fill="#2C2DA3" />
                    <path d="M11.4609 13.7969H10.0078C10.013 13.2969 10.0573 12.888 10.1406 12.5703C10.2292 12.2474 10.3724 11.9531 10.5703 11.6875C10.7682 11.4219 11.0312 11.1198 11.3594 10.7812C11.599 10.5365 11.8177 10.3073 12.0156 10.0938C12.2188 9.875 12.3828 9.64062 12.5078 9.39062C12.6328 9.13542 12.6953 8.83073 12.6953 8.47656C12.6953 8.11719 12.6302 7.80729 12.5 7.54688C12.375 7.28646 12.1875 7.08594 11.9375 6.94531C11.6927 6.80469 11.388 6.73438 11.0234 6.73438C10.7214 6.73438 10.4349 6.78906 10.1641 6.89844C9.89323 7.00781 9.67448 7.17708 9.50781 7.40625C9.34115 7.63021 9.25521 7.92448 9.25 8.28906H7.80469C7.8151 7.70052 7.96094 7.19531 8.24219 6.77344C8.52865 6.35156 8.91406 6.02865 9.39844 5.80469C9.88281 5.58073 10.4245 5.46875 11.0234 5.46875C11.6849 5.46875 12.2474 5.58854 12.7109 5.82812C13.1797 6.06771 13.5365 6.41146 13.7812 6.85938C14.026 7.30208 14.1484 7.82812 14.1484 8.4375C14.1484 8.90625 14.0521 9.33854 13.8594 9.73438C13.6719 10.125 13.4297 10.4922 13.1328 10.8359C12.8359 11.1797 12.5208 11.5078 12.1875 11.8203C11.901 12.0859 11.7083 12.3854 11.6094 12.7188C11.5104 13.0521 11.4609 13.4115 11.4609 13.7969ZM9.94531 16.2734C9.94531 16.0391 10.0182 15.8411 10.1641 15.6797C10.3099 15.5182 10.5208 15.4375 10.7969 15.4375C11.0781 15.4375 11.2917 15.5182 11.4375 15.6797C11.5833 15.8411 11.6562 16.0391 11.6562 16.2734C11.6562 16.4974 11.5833 16.6901 11.4375 16.8516C11.2917 17.013 11.0781 17.0938 10.7969 17.0938C10.5208 17.0938 10.3099 17.013 10.1641 16.8516C10.0182 16.6901 9.94531 16.4974 9.94531 16.2734Z" fill="white" />
                  </svg>
                </button>
                <Link to="/payments/register" className="d-flex justify-content-between align-items-center w-100">

                <h5 className="text-blue mb-0">
                  Registra tu pago
                </h5>
                <RegisterPaymentIcon />
                <div id="modal-register-payment" className="modal fade">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <h4 className="modal-title text-center mx-auto text-blue w-100 pl-5 pt-2">Registra tu pago</h4>
                        <button type="button" className="close" data-dismiss="modal">
                          <span aria-hidden="true">×</span>
                          <span className="sr-only">cerrar</span>
                        </button>
                      </div>
                      <div className="modal-body text-justify mx-3" style={{ fontSize: '15px' }}>
                        <p>
                          Si ya realizaste un pago, aquí puedes subir tu comprobante para que tu condominio pueda verificar tu abono.
                        </p>
                      </div>
                      <div className="modal-footer text-center border-0 mb-4">
                        <button type="button" className="btn bg-blue mx-auto" data-dismiss="modal">Aceptar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              </div>
            ) : ''}
          </div>
        </div>
      ) : (
        ''
      )}
    </Section>
  );
}

export default PayOptions

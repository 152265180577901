export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const SELECT_ADDRESS_START = "SELECT_ADDRESS_START";
export const SELECT_ADDRESS_SUCCESS = "SELECT_ADDRESS_SUCCESS";
export const SELECT_CHARGES_START = "SELECT_CHARGES_START";
export const SELECT_CHARGES_SUCCESS = "SELECT_CHARGES_SUCCESS";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const loginUserStart = (username, password, history) => ({
  type: LOGIN_USER_START,
  payload: { username, password, history },
});

export const loginUserSuccess = (user, jwt, resident) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { user, jwt, resident },
});

export const loginUserFailed = (message) => ({
  type: LOGIN_USER_FAILED,
  payload: { message },
});
export const resetPasswordStart = (code, password, passwordConfirm, history) => ({
  type: RESET_PASSWORD_START,
  payload: { code, password, passwordConfirm, history },
});

export const resetPasswordSuccess = (user, jwt, resident) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: { user, jwt, resident },
});

export const resetPasswordFailed = (message) => ({
  type: RESET_PASSWORD_FAILED,
  payload: { message },
});
export const logoutUser = (user, jwt) => ({
  type: LOGOUT_USER,
  payload: { user, jwt },
});
export const registerUser = (user) => ({
  type: REGISTER_USER,
  payload: { user },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: { user },
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});
export const selectAddressStart = (selectedAddress, resident) => ({
  type: SELECT_ADDRESS_START,
  payload: { selectedAddress, resident },
});
export const selectAddressSuccess = (selectedAddress, resident) => ({
  type: SELECT_ADDRESS_SUCCESS,
  payload: { selectedAddress, resident },
});
export const selectChargesStart = (selectedCharges) => ({
  type: SELECT_CHARGES_START,
  payload: { selectedCharges },
});
export const selectChargesSuccess = (selectedCharges) => ({
  type: SELECT_CHARGES_SUCCESS,
  payload: { selectedCharges },
});

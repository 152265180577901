import React, { useEffect, useState } from "react";
import Section from "../layouts/Section";
import { NavLink, Redirect, useHistory, useParams } from "react-router-dom";
import { resetPasswordStart } from "../../redux/auth/actions";
import { connect } from "react-redux";
import axios from "axios";
import { servicePath } from "../../constants/defaultsValues";

// const goBack = () => {
//   useHistory.goBack();
// };
/* VALIDATIONS */
/* END VALIDATIONS */
const ResetPassword = (jwt, message) => {
  localStorage.clear();
  const history = useHistory();
  const { token } = useParams();
  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  // const [passwordConfirm, setPasswordConfirm] = useState("");
  // const [submit, setSubmit] = useState(false);
  const [errors] = useState({ password: null, username: null });
  const jasonWebToken = jwt.jwt;

  async function userFetch() {
    axios
      .post(
        `${servicePath}/residents/new/confirm`,
        {
          token,
        },
      )
      .then((res) => {
        setUser(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    userFetch();
  }, []);

  // const validateReset = (pass, passConfirm) => {
  //   const validPass = validatePassword(pass);
  //   const validPassConfirm = validatePassword(passConfirm);
  //   setErrors({
  //     password: validPass,
  //     passwordConfirm: validPassConfirm,
  //   });
  //   return !validPass && !validPassConfirm;
  // };

  const onSubmit = async () => {
    // const valid = await validateReset();
    // if (valid) {
    axios
      .post(
        `${servicePath}/email/change-email`,
        {
          user: user.id,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("🚀 ~ file: resetPassword.js ~ line 78 ~ .then ~ res", res);
        history.push('/');
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  if (jasonWebToken !== null) {
    return <Redirect to="/">Error, ya hay una cuenta iniciada</Redirect>;
  } else {
    return (
      <Section
        allNotification={false}
        searchPopup={true}
        title={"Login"}
        disabledHeader={true}
        disabledFooter={true}
      >
        <section
          className="section-login"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/Fondo-login.png" +
              ")",
            height: '1000px',
          }}
        >
          <div className="page-title bg-transparent">
            {/* <div className="container pt-3">
              <button
                className="text-left home-clicked go-back"
                onClick={goBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </button>
            </div> */}
          </div>
          <div className="text-center mg-bottom-40 title">
            <div className="col-md-4 col-5 mx-auto mb-5">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/civity-logo.svg"}
                alt="civity"
              />
            </div>
            <div className="col-12 text-center">
              <h4 className="text-center">Nuevo correo electrónico</h4>
            </div>
          </div>
          <div className="Login-area">
            <div className="container">
              <form className="contact-form-inner bg-transparent">
                {jwt.message ? (
                  <div className="col-12 text-center mx-auto mb-3">
                    <span className="text-danger mx-auto">{jwt.message}</span>
                  </div>
                ) : null}
                <label className="single-input-wrap">
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Contraseña"
                  />
                  {errors.password ? (
                    <span className="text-danger">{errors.password}</span>
                  ) : null}
                </label>
                {/* <label className="single-input-wrap">
                  <input
                    type="password"
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    value={passwordConfirm}
                    placeholder="Confirmar contraseña"
                  />
                  {errors.password ? (
                    <span className="text-danger">{errors.password}</span>
                  ) : null}
                </label> */}
                <NavLink to="/login">
                  <div className="text-right">
                    <span>¿Ya tienes una cuenta? Inicia Sesión</span>
                  </div>
                </NavLink>
                <div className="w-100 text-center">
                  <button
                    className="btn btn-login mx-auto"
                    onClick={() => onSubmit()}
                  >
                    Establecer contraseña
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Section>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    jwt: state.jwt,
    message: state.error,
  };
};

export default connect(mapStateToProps, { resetPasswordAction: resetPasswordStart })(
  ResetPassword
);

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
// import AutoNotification from "./AutoNotification";
// const moment = require("moment/locale/es-mx");

function Section({
  disabledHeader,
  title,
  searchPopup,
  withBack,
  noBorder,
  cancelTo,
  children,
  disabledFooter
}) {
  return (
    <>
      {!disabledHeader ? <Navbar /> : null}
      {/* this.props.autoNotification ? <AutoNotification /> : "" */}
      {!disabledHeader ? (
        <>
          <Header
            title={title}
            searchPopup={searchPopup}
            withBack={withBack}
            noBorder={noBorder}
            cancelTo={cancelTo}
          />
          <img
            className="onda"
            src={process.env.PUBLIC_URL + "/assets/img/onda-header.svg"}
            alt="onda-civity"
          />
        </>
      ) : null}
      <div className="page">{children}</div>
      {!disabledFooter ? <Footer /> : null}
    </>
  );
}

export default Section;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
moment.locale("es-MX");
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import qs from 'qs';
import Section from "../layouts/Section";
import SelectAddress from "../layouts/SelectAddress";
import { servicePath } from "../../constants/defaultsValues";

const Reservations = () => {
  // const user = useSelector((state) => state.user);
  const address = useSelector((state) => state.address);
  const token = useSelector((state) => state.jwt);

  const apiUrl = `${servicePath}/reservations`;
  const apiIcons = `${servicePath}/icons`;
  // const apiStatus = `${servicePath}/status-incidences`;
  // const [items, setItems] = useState([]);
  const [reservations, setReservations] = useState([]);
  // const [status, setStatus] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [historial, setHistorial] = useState(false);
  const [icons, setIcons] = useState([]);
  // const [filterSearch, setFilterSearch] = useState(false);

  // const getIcons = () => {
  //   reservations.forEach((reservation) => {
  //     axios.get(`${apiIcons}/${reservation.area.icon}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }).then((res) => {
  //       setIcons([...icons, res.data]);
  //       console.log(icons);
  //     })
  //       .catch((err) => {
  //         console.log(err);
  //       }
  //       );
  //   });
  // };

  async function fetchData() {
    const query = historial ?
      `&date_lte=${moment(new Date()).format('YYYY-MM-DD').concat(' 00:00')}` :
      `&date_gte=${moment(new Date()).format('YYYY-MM-DD HH:mm')}&cancelled_ne=true`;
    // const query = qs.stringify({
    //   _where: [
    //     { date_gte: moment(new Date()).format('YYYY-MM-DD HH:mm') },
    //     { date_lte: moment(new Date()).format('YYYY-MM-DD 00:00') },
    //   ],
    // });
    const reservationsRequest = axios
      .get(
        `${apiUrl}?_limit=-1&_sort=date:DESC&address=${address}${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    const iconsRequest = axios.get(`${apiIcons}?_limit=-1`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const [reservationsResponse, iconsResponse] = await Promise.all([reservationsRequest, iconsRequest]);
    setReservations(reservationsResponse.data);
    setIcons(iconsResponse.data);
    setIsLoaded(true);
  }
  // console.log(items);
  useEffect(() => {
    fetchData();
  }, [historial]);

  // const applyFilters = (value) => {
  //   if (value !== "") {
  //     const aux = items.filter((d) => d.status.id === Number(value));
  //     setReservations(aux);
  //   } else {
  //     setReservations(items);
  //   }
  // };

  const deleteItem = (id) => {
    axios
      .delete(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => fetchData());
  };

  // const onKeyFilter = (value) => {
  //   setFilterSearch(value);
  // };

  return (
    <Section title="Reservaciones">
      {isLoaded ? (
        <div className="transaction-area pt-3">
          <div className="container">
            <SelectAddress />
          </div>
          <div className="container my-4">
            <div className="d-flex justify-content-center">
              <button
                type="button"
                onClick={() => setHistorial(false)}
                className={`${historial ? 'btn-muted' : 'btn-reservas'} btn btn-reservas mr-4 col-5 d-flex align-items-center justify-content-center text-center`}
              >
                Reservas
              </button>
              <button
                onClick={() => setHistorial(true)}
                className={`${historial ? 'btn-reservas' : 'btn-muted'} btn col-5 d-flex align-items-center justify-content-center text-center`}
              >
                Historial
              </button>
            </div>
            {/* <div className="col-12  d-flex new-search input">
              <div className="col-2 p-0  d-flex justify-content-end ">
                <i className="xsmall-icon  icon-search" />
              </div>
              <div className="col-10 p-0 d-flex align-items-stretch">
                {" "}
                <input
                  type="text"
                  placeholder="Buscar"
                  className="form-control border-0 w-100 bg-beige "
                  onKeyUp={(e) => onKeyFilter(e.target.value)}
                />{" "}
              </div>
            </div> */}
            {/* <div className="col-12">
              <div className="row mx-auto div-filters align-items-center">
                <div className="col-4 text-center">Estado</div>
                <div className="col-8 p-0 div-select bg-beige p-2">
                  <select
                    className="form-select bg-beige  w-100"
                    defaultValue=""
                    onChange={(e) => applyFilters(e.target.value)}
                  >
                    <option value="">Todas</option>
                    {status.map((state, key) => (
                      <option value={state.id} key={key}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div> */}
          </div>
          <div className="container pt-2">
            <div className="col-12 text-center text-title">
              {reservations.length > 0 && !historial ? 'Tus reservas actuales' : reservations.length > 0 && historial ? 'Historial de reservas' : 'No hay reservas'}
            </div>
            <section className="transaction-area pt-3 section-incidences">
              {/* <div className="container"> */}
              <div className="transaction-inner row align-items-stretch">
                {/* eslint-disable-next-line array-callback-return */}
                {reservations.map((item, key) => {
                  if (!historial && moment(item.date).isAfter(new Date())) {
                    return (
                      <ReservationCard
                        item={item}
                        key={key}
                        deleteItem={deleteItem}
                        icons={icons}
                      // historial={historial}
                      />
                    )
                  } else if (!historial &&
                    moment(item.date).format("DD/MM/YYYY") ===
                    moment().format("DD/MM/YYYY") &&
                    moment(item.start, 'HH').format('HH') > moment().format('HH')
                  ) {
                    return (
                      <ReservationCard
                        item={item}
                        key={key}
                        deleteItem={deleteItem}
                        icons={icons}
                      // historial={historial}
                      />
                    )
                  } else if (historial && moment(item.date).isBefore(new Date())) {
                    return (
                      <ReservationCard
                        item={item}
                        key={key}
                        deleteItem={deleteItem}
                        icons={icons}
                        historial={historial}
                      />
                    )
                  }
                  // else if (historial && moment(item.date).format('DD-MM-YYYY') ===
                  //   moment().format('DD-MM-YYYY') &&
                  //   moment(item.end, 'HH').format('HH') < moment().format('HH')
                  // ) {
                  //   return (
                  //     <>
                  //       <h1 className="text-blue">En curso:</h1>
                  //       <ReservationCard
                  //         item={item}
                  //         key={key}
                  //         deleteItem={deleteItem}
                  //         icons={icons}
                  //       // historial={historial}
                  //       />
                  //     </>
                  //   )
                  // }
                })}
              </div>
              {/*  </div> */}
              <div className="container text-center mt-4">
                <Link
                  to="/reservations/create"
                  className="btn btn-new-incidence col-8 col-md-6 col-lg-4 mx-auto d-flex align-items-center justify-content-center text-center"
                >
                  Crear reserva
                </Link>
              </div>
            </section>
          </div>
        </div>
      ) : (
        ""
      )}
    </Section>
  );
};

const ReservationCard = ({ item, deleteItem, icons, historial }) => {
  return (
    <>
      <div className="col-12 col-sm-12 col-md-6 col-xl-4 my-1 d-flex w-100">
        <Link
          to={`/reservation/${item.id}`}
          className="col-12 my-1"
        >
          <div className="card-incidences">
            <div className="col-12 py-2 px-2 d-flex">
              <div className="col-10 w-100 p-0 d-flex align-items-center container-text">
                <div className="w-30 pr-2">
                  {icons && icons.find((d) => d.id === item.area.icon) && (
                    <img
                      className="ml-3"
                      src={`/assets/icons/${icons && icons.find((d) => d.id === item.area.icon)?.fileName}`}
                      width="40"
                      height="40"
                      alt="icon"
                    />
                  )}
                </div>
                <div className="w-70 details pl-2">
                  <h5>{item.area.name}</h5>
                  <span className="text-dark pb-0 font-weight-bold">
                    {moment(item.date).format("DD/MM/YYYY")}{" "}
                    {/* {moment(item.date).format("MMMM")}{" "}
                    {moment(item.date).format("YYYY")} */}
                  </span>{" "}
                  <br />
                  <span>
                    {item.start?.substring(0, 5)} a {item.end?.substring(0, 5)}
                  </span>
                  <br />
                  <span>
                    Costo: ${item.blockArea.cost}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="d-absolute mt-1" style={{ transform: 'translateX(-55px)' }}>
          {!historial ? (
            <button
            type="button"
            className="btn-c btn bg-transparent border-0 p-0 m-0 w-100"
            data-toggle="modal"
            data-target={`#modal-delete-${item.id}`}
          >
            <i className="xsmall-icon icon-close pl-0" />
          </button>
          ) : ''}
          
        </div>
      </div>
      <div id={`modal-delete-${item.id}`} className="modal fade">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* <div className="modal-header">
                                  <h4 className="modal-title">Modal Title</h4>
                                  <button type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Cancelar</span>
                                  </button>
                                </div> */}
            <div className="modal-body text-center my-4">
              <p>Estas por cancelar tu reserva</p>
              <div className="px-2 py-2 text-center">
                <h5 className="pb-2 text-blue">{item.area.name}</h5>
                <span className="text-dark pb-0 font-weight-bold">
                  {moment(item.date).format("DD")}{" "}
                  {moment(item.date).format("MMMM")}{" "}
                  {moment(item.date).format("YYYY")}
                </span>{" "}-{" "}
                <span>
                  {item.start?.substring(0, 5)} a {item.end?.substring(0, 5)}
                </span>
                <br />
                <span>
                  Precio: ${item.blockArea.cost}
                </span>
              </div>
              <h4 className="text-blue font-weight-bold">¿Deseas continuar?</h4>
            </div>
            <div className="modal-footer justify-content-between mx-3">
              <button type="button" className="btn btn-shadow w-40  my-2" data-dismiss="modal">Cancelar</button>
              <button onClick={() => deleteItem(item.id)} type="button" className="btn btn-red w-40 my-2" data-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reservations;

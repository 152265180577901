import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  SELECT_CHARGES_SUCCESS,
  SELECT_CHARGES_START,
  SELECT_ADDRESS_START,
  SELECT_ADDRESS_SUCCESS,
} from "./actions";
const initialState = {
  user: null,
  jwt: null,
  error: null,
  address: null,
  resident: null,
  condominium: null,
};

const reducer = (state = initialState, action) => {
  switch ((initialState, action.type)) {
    case LOGIN_USER_START:
      return {
        ...state,
        username: action.payload.username,
        error: null,
        password: action.payload.password,
      };
    // break;
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        jwt: action.payload.jwt,
        address: action.payload.resident.addresses.length
          ? action.payload.resident.addresses[0].id
          : null,
        condominium: action.payload.resident.addresses.length
        ? action.payload.resident.addresses[0].condominium
        : null,
        resident: action.payload.resident,
        error: null,
        // password: null,
      };
    // break;
    case LOGIN_USER_FAILED:
      return {
        ...state,
        user: null,
        jwt: null,
        address: null,
        error: action.payload.message,
      };
    // break;
    case RESET_PASSWORD_START:
      return {
        ...state,
        username: action.payload.username,
        error: null,
        password: action.payload.password,
      };
    // break;
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        jwt: action.payload.jwt,
        address: action.payload.resident.addresses.length
          ? action.payload.resident.addresses[0].id
          : null,
        resident: action.payload.resident,
        error: null,
        password: null,
      };
    // break;
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        user: null,
        jwt: null,
        address: null,
        error: action.payload.message,
      };
    // break;
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        jwt: null,
        address: null,
        error: "",
      };
    // break;
    case REGISTER_USER:
      return { ...state, error: "" };
    // break;
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        // jwt: action.payload.jwt,
        address: action.payload.resident.addresses.length
          ? action.payload.resident.addresses[0].id
          : null,
        resident: action.payload.resident,
        error: "",
      };
    // break;
    case REGISTER_USER_ERROR:
      return {
        ...state,
        user: null,
        jwt: null,
        address: null,
        error: action.payload.message,
      };
    // break;
    case SELECT_ADDRESS_START:
      return {
        ...state,
        address: action.payload.selectedAddress,
        resident: action.payload.resident,
        error: null,
      }
    // break;
    case SELECT_ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.payload.resident.addresses.length
          ? action.payload.resident.addresses.find(
              (a) => a.id === action.payload.selectedAddress
            ).id
          : null,
        condominium: action.payload.resident.addresses.length
        ? action.payload.resident.addresses.find(
            (a) => a.id === action.payload.selectedAddress
          ).condominium
        : null,
        error: null,
      }
    // break;
    case SELECT_CHARGES_START:
      return {
        ...state,
        selectedCharges: action.payload.selectedCharges,
        error: "",
      }
    // break;
    case SELECT_CHARGES_SUCCESS:
      return {
        ...state,
        selectedCharges: action.payload.selectedCharges,
        error: "",
      }
    // break;
    default:
      return {
        ...state,
        error: null,
      };
    // break;
  }
};

export default reducer;

import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return (
      <>
        <div className="footer-area">
          {/*  <div
            className="footer-top text-center"
            style={{ backgroundImage: "url(assets/img/bg/7.png)" }}
          >
            <div className="container">
              <p>
                Copyright © Bankapp 2020. All Rights Reserved. Designed By
                S7template
              </p>
            </div>
          </div> */}
          <div className="container">
            <div className="footer-bottom text-center">
              <ul>
                <li>
                  <Link className="home-clicked" to={"/"}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/menu/domicilio.svg"
                      }
                      alt="pagos-civity"
                    />
                    <p>Home</p>
                  </Link>
                </li>
                <li>
                  <Link to={"/payments"}>
                    <i className="large-icon icon-pagos" />
                    <p>Pagos</p>
                  </Link>
                </li>
                <li>
                  <Link to={"/reservations"}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/menu/reservaciones.svg"
                      }
                      alt="reservaciones-civity"
                    />
                    <p>Reservas</p>
                  </Link>
                </li>
                <li>
                  <Link to={"/news"}>
                    <i className="large-icon icon-noticias" />
                    <p>Noticias</p>
                  </Link>
                </li>
                {/* <li>
                  <div className="menu-bar">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/menu/mensajes.svg"
                      }
                      alt="pagos-civity"
                    />
                    <p>Mensajes</p>
                  </div>
                </li> */}
                {/* <li>
                  <Link to={"/carts"}>
                    <i className="fa fa-home" />
                    <p>My Card</p>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        {/* Back to Top Area */}
        <div className="back-to-top">
          <span className="back-top">
            <i className="fa fa-angle-up" />
          </span>
        </div>
      </>
    );
}

export default Footer;

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { servicePath } from "../../constants/defaultsValues";
import { selectAddressStart } from "../../redux/auth/actions";

const SelectAddress = ({ address, selectAddressAction }) => {
  const apiUrl = `${servicePath}/addresses`;
  const resident = useSelector((state) => state.resident);
  const token = useSelector((state) => state.jwt);
  const [items, setItems] = useState([]);
  async function fetchData() {
    axios
      .get(`${apiUrl}?_limit=-1&residents=${resident.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setItems(res.data);
      });
  }

  const selectAddress = async (e) => {
    await selectAddressAction(e.id, resident);
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <div className="container my-3">
      <div className="row d-flex align-items-center border-0 bg-transparent div-address mx-auto">
        <div className="col-3 text-center">
          <i className="small-icon icon-home" />
        </div>
        <div className="col-9 p-0 div-select bg-beige p-2">
          <select
            className="form-select w-100 bg-beige"
            defaultValue={items.findIndex(i => i.id === address)}
            onChange={(e) => selectAddress(items[e.target.value])}
          >
            {items.length && items.map((x, key) => (
              <option value={key} key={key}>
                {x.street} {x.number} {x.numInterior}, {x.condominium.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* <div className="container">
      <div className="ba-add-balance-title d-flex align-items-center border-0 bg-transparent div-address mx-auto">
        <div className="col-2">
          <i className="small-icon icon-home" />
        </div>
        <div className="col-10 p-0 div-select bg-beige p-2">
          <select className="form-select  w-100 bg-beige" defaultValue="">
            {items.map((x, key) => (
              <option value={x.condominium.id} key={key}>
                {x.street} {x.number}, {x.condominium.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div> */}
    </div>
  );
  //  }
};

const mapStateToProps = ({ address }) => {
  return { address };
};

export default connect(mapStateToProps, {
  selectAddressAction: selectAddressStart,
})(SelectAddress);

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Section from '../../layouts/Section';
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { servicePath, stripePk } from '../../../constants/defaultsValues';

let stripe = {};
let elements = {};
const apiUrl = `${servicePath}/payment-methods/app/stripe`;
function AddPaymentCard() {
  const [addedSuccess, setAddedSuccess] = useState(false);
  const [addedFail, setAddedFail] = useState(false);
  const [addedFailError, setAddedFailError] = useState(null);
  const state = useSelector((state) => state);
  const [stripePromise] = useState(() => loadStripe(
    stripePk,
    { stripeAccount: state.resident.condominiums.find((c) => c.id === state.condominium).stripeAccount }
  ))
  const [isLoaded, setIsLoaded] = useState(true);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [cardName, setCardName] = useState(null);
  const [address, setAddress] = useState(null);
  const [isMain, setIsMain] = useState(false);

  const onSubmit = async () => {
    try {
      // CREATE THE PAYMENT METHOD
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          email: state.user.email,
          name: cardName,
          phone: state.user.phone || null,
          address: {
            country: 'MX',
            line1: address,
          },
        },
      });
      // SEND THE PAYMENT METHOD AND ATTACHED TO THE CUSTOMER
      if (!result.error) {
        axios
          .post(
            `${apiUrl}`,
            {
              user: state.user,
              newPaymentMethod: result.paymentMethod,
              condominium: state.condominium,
              isMain,
            },
            {
              headers: {
                Authorization: `Bearer ${state.jwt}`,
              },
            }
          )
          .then((res) => {
            setIsLoaded(true);
            if (res.data?.type === "StripeCardError") {
              setAddedFailError(res.data);
              setAddedFail(true);
            } else {
              setAddedSuccess(true);
            }
          })
          .catch((err) => {
            setAddedFail(true);
            console.log(err);
          });
      } else {
        console.log(result.error);
        setAddedFail(true);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
      setIsLoaded(true);
      setAddedFail(true);

    }
  };

  return (
    <Section title={"Pagos"} withBack>
      {isLoaded ? (
        <>
          {!addedSuccess && !addedFail ? (
            <div className="container pb-5">
              <h5 className="font-weight-bold text-blue text-center my-4">
                Agregar una tarjeta
              </h5>
              <div className="card-incidences bg-beige p-3">
                <p className="text-blue text-center">
                  Ingresa la información de tu tarjeta
                </p>
                <form id="clients-form">
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      error={error}
                      setDisabled={setDisabled}
                      setError={setError}
                      setCardName={setCardName}
                    />
                  </Elements>
                  <label htmlFor="address" className="text-blue font-weight-bold d-block mt-4">
                    Dirección de la tarjeta
                  </label>
                  <input
                    id="address"
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Calle"
                    className="p-2 bg-gray rounded border-0 w-100"
                  />
                  <div className="mt-4">
                    <input
                      type="checkbox"
                      id="isMain"
                      value={isMain}
                      onChange={() => setIsMain(!isMain)} />
                      {' '}
                    <label>Asignar como principal</label>
                  </div>
                  <div className="text-center mt-4">
                    <h6>
                      Tu información bancaria y pagos
                    </h6>
                    <img src="./assets/img/payments/Pago-seguro.svg" alt="safe pay" className="w-30" />
                  </div>
                  <div className="text-center w-100">
                    <button type="button" disabled={disabled} onClick={onSubmit} className="btn bg-blue mb-4 px-5">
                      Agregar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : ''}
          {addedSuccess ? (
            <AddedSuccess
              setAddedSuccess={setAddedSuccess}
            />
          ) : ''}
          {addedFail ? (
            <AddedFail
              setAddedFail={setAddedFail}
              addedFailError={addedFailError}
            />
          ) : ''}
        </>
      ) : (
        ''
      )}
    </Section>
  );
}

const CheckoutForm = ({
  error,
  setDisabled,
  setCardName,
  setError,
}) => {
  stripe = useStripe();
  elements = useElements();
  const cardNumberStyle = {
    // iconStyle: 'solid',
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#2b2baf',
        color: '#333',
        fontSize: '18px',
        '::placeholder': {
          color: '#ccc',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
  };

  const handleNumberChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };
  useEffect(() => {
    switch (error) {
      case "Your card's expiration year is in the past.":
        setError('El año de expiración de tu tarjeta esta el pasado.');
        break;
      case "Your card's expiration date is in the past.":
        setError('La fecha de expiración de tu tarjeta esta el pasado.');
        break;
      case "Your card's security code is incomplete.":
        setError('El codigo de seguridad de tu tarjeta esta incompleto.');
        break;
      case "Your card's expiration date is incomplete.":
        setError('La fecha de expiración esta incompleta.');
        break;
      case 'Your card number is invalid.':
        setError('El número de tu tarjeta es inválido');
        break;
      case 'Your card number is incomplete.':
        setError('El número de tu tarjeta esta incompleto');
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <label htmlFor="name" className="text-blue font-weight-bold d-block mt-4">
        Nombre en la tarjeta
      </label>
      <input
        id="name"
        onChange={(e) => setCardName(e.target.value)}
        placeholder="Nombre Apellido"
        className="p-2 bg-gray rounded border-0 w-100"
      />
      <label htmlFor="card-number-element" className="text-blue font-weight-bold d-block mt-4">
        Número de la tarjeta
      </label>
      <div className="border border-primary mb-4 rounded-lg p-1 py-2 bg-white">
        <CardElement
          id="card-element"
          options={cardNumberStyle}
          onChange={handleNumberChange}
        />
      </div>
      {error && <div className="p-1 d-block text-red mt-1">{error}</div>}
    </>
  );
};

const AddedSuccess = ({ setAddedSuccess }) => {
  return (
    <div className="text-center pt-5">
      <img src="./assets/img/payments/Pago_exitoso.svg" alt="success" className="w-30" />
      <h1 className="text-blue" style={{ fontSize: '45px' }}>
        ¡Tu tarjeta <br />fue agregada <br />con éxito!
      </h1>
      <button onClick={() => setAddedSuccess(false)} className="btn p-1 bg-transparent text-16 text-blue">
        <u>Agregar Tarjeta</u>
      </button>
      <Link
        to="/payments/pay"
        className="btn btn-short mt-5 col-7 col-md-5 col-lg-3 mx-auto d-flex align-items-center justify-content-center text-center"
      >
        Terminar
      </Link>
    </div>
  );
}

const AddedFail = ({ setAddedFail, addedFailError }) => {
  return (
    <div className="text-center pt-5">
       <img src="./assets/img/civity-logo-blue.svg" alt="civity" className="w-40 mb-5" />
      <h1 className="text-red w-80 mx-auto" style={{ fontSize: '35px' }}>
        {addedFailError && addedFailError.code === 'card_declined' ? 'Tu tarjeta a sido declinada' : 'Tu tarjeta no pudo ser agregada, intente más tarde.'}
      </h1>
      <p className="mt-4 mx-4">
        Si el problema persiste, comunícate con tu banco o con el servicio de atención al cliente de tu tarjeta.
      </p>
      {/* <div className="btn-short d-flex align-items-center justify-content-between px-4 mt-5 w-75 mx-auto">
        <span className="font-weight-normal">Pago realizado</span>
        <span>00</span>
      </div> */}
      {/* <div className="mt-4 font-weight-bold">
        <div>
          <span>Fecha de pago: </span>
          {moment(new Date()).locale(false).format('DD MMMM YYYY')}
        </div>
        <div>
          <span>Tarjeta: </span>
          XXXX-XXXX-XXXX-{selectedCard?.card?.last4}
        </div>
        <div>
          <span>Cliente: </span>
          {resident.name} {resident.lastname}
        </div>
        <div>
          <span>No. de Boleta: </span>
          {quota.idMovement}
        </div>
      </div> */}
      <div className="text-center pt-5">
        <button onClick={() => setAddedFail(false)} className="btn p-1 bg-transparent text-16 text-blue">
          <u>Reintentar</u>
        </button>
        <Link
          to="/payments/pay"
          className="btn btn-short mt-5 col-7 col-md-5 col-lg-3 mx-auto d-flex align-items-center justify-content-center text-center"
        >
          Terminar
        </Link>
      </div>
    </div>
  );
}

export default AddPaymentCard;
